<template>
	<div class="chirp-players" :class="{ 'has-attachment': chirp.route.params.file }">

		<div v-if="chirp.route.params.file" class="attachment" :class="chirp.route.params.file.file_type">
			<div class="icon">
				<div class="icon-name">{{ chirp.route.params.file.file_type | file_type_to_str }}</div>
				<div class="icon-content">
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
				</div>
			</div>
			<div class="title">{{ chirp.route.params.file.title }}</div>
		</div>

		<div class="form">
			<p class="title">Send to</p>
			<div class="input">
				<ui-input v-model="q" placeholder="Search" ref="search" />
			</div>
		</div>
		
		<div class="scroll" v-scrollbar>
			<div>
				<div class="player-groups">
					<div v-for="group in groups" v-if="players.filter(p => p.role.category == group.category && !p.role.npc && p.name != 'NPC' ).length > 0" :key="group.id" class="player-group" :class="{ expanded: group.expanded }">
						<div class="player-group-title" v-hammer:tap="() => toggle_group(group)">{{ group.name }} ({{ players.filter(p => p.role.category == group.category && !p.role.npc && p.name != 'NPC' ).length }})</div>
						<div v-show="group.expanded" class="players">
							<div v-for="player in players" v-if="player.role.category == group.category && !player.role.npc && player.name != 'NPC'" class="player">
								<div class="picture" v-bg="{ name: player.avatar, type: 'avatar' }"></div>
								<div class="player-content">
									<div class="job">{{ player.role.name }}</div>
									<div class="names">
										<span v-if="player.name" class="role-name">{{ player.name }}</span>
										<span v-if="player.student_name" class="player-name"> ({{ player.student_name }})</span>
									</div>
									<div v-if="!chirp.route.params.file" class="action new-message" v-hammer:tap="() => open_conversation(player)">
										<ui-icon name="new" :size="20" />
									</div>
									<div v-if="chirp.route.params.file" class="action send-file" :class="(sending.find((s) => s.player_id === player.id && s.file_id === chirp.route.params.file.id) || { state: '' }).state" v-hammer:tap="() => send_file(player)">
										<span class="text default">Send</span>
										<span class="text success">Sent</span>
										<span class="loading"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiInput from '@/components/ui/ui-input'
import { slugify } from '@/js/utils/utils'

export default {
	name: 'chirp-players',
	data() {
		return {
			sending: [],
			q: '',
			groups: [
				{ id: 1, category: 'Government', name: "Government officials", expanded: true },
				{ id: 2, category: 'Law Enforcement', name: "Law enforcement", expanded: true },
				{ id: 3, category: 'Media', name: "Media", expanded: true },
				{ id: 4, category: 'Citizens', name: "Citizens", expanded: true }
			]
		}
	},
	computed: {
		...mapGetters([ 'game', 'chirp', 'logged_user' ]),
		players() {
			let players = this.game.players.filter((p) => parseInt(p.id) !== parseInt(this.logged_user.id))
			if (this.q) {
				let words = this.q.trim().split(' ')
				let slugs = []
				for (let i in words) {
					let slug = slugify(words[i])
					if (slug && slug.length > 1) slugs.push(slug)
				}
				let regex = new RegExp(slugs.join('|'))
				players = players.filter((p) => {
					let name = false
					let student_name = false
					let role = false
					if (p.name) name = slugify(p.name).toLowerCase().match(regex)
					if (p.student_name) student_name = slugify(p.student_name).toLowerCase().match(regex)
					if (p.role.name) role = slugify(p.role.name).toLowerCase().match(regex)

					return name || student_name || role
				})
			}
			return players
		}
	},
	filters: {
		file_type_to_str(value) {
			if (value == 'confidential') return "Confidential"
			if (value == 'secret') return "secret"
			if (value == 'top-secret') return "Top secret"
			if (value == 'memo') return "Memo"
			return "Document"
		}
	},
	watch: {
		'chirp.route.params.file'() {
			this.loading_sending()
		}
	},
	methods: {
		toggle_group(group) {
			group.expanded = !group.expanded
		},
		open_conversation(player) {
			this.$store.dispatch('set_chirp_route', { name: 'conversation', params: { player } })
		},
		async send_file(player) {
			let sended = this.sending.find((s) => s.player_id === player.id && s.file_id === this.chirp.route.params.file.id)
			if (!sended) {
				sended = { player_id: player.id, file_id: this.chirp.route.params.file.id, state: 'sending' }
				this.sending.push(sended)

				let shared = await this.$store.dispatch('share_player_file', { player_id: player.id, file_id: this.chirp.route.params.file.id })
				if (shared) {
					let message_id = await this.$store.dispatch('send_message', {
						title: '',
						message: "File \"" + this.chirp.route.params.file.title + "\" shared",
						recipient: player,
						channel: '',
						tags: [],
						message_type: 'Chirp',
						medias: []
					})
					if (message_id) {
						sended.state = 'sended'
						let items = localStorage.getItem('sre-sended-files')
						if (items) items = JSON.parse(items)
						if (!items) items = []
						items.push(sended)
						localStorage.setItem('sre-sended-files', JSON.stringify(items))
					}
				}
			}
		},
		loading_sending() {
			if (this.chirp.route.params.file && this.chirp.route.params.file.id) {
				let items = localStorage.getItem('sre-sended-files')
				if (items) {
					items = JSON.parse(items)
					this.sending = items.filter((i) => parseInt(i.file_id) === parseInt(this.chirp.route.params.file.id))
				}
			}
		}
	},
	mounted() {
		const input = this.$el.querySelector('.form .input input')
		if (input) input.focus()

		this.loading_sending()
	},
	components: { UiInput }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.chirp-players
	height 100%
	&.has-attachment 
		> .scroll
			height calc(100% - 196px)
	.attachment
		height 96px
		background-color var(--color-chirp-players-attachment-bg)
		margin 16px 16px 0 16px
		padding 8px 8px 8px 0
		border-radius 4px
		&.memo .icon
			background-color var(--color-doc-memo)
		&.top-secret .icon
			background-color var(--color-doc-top-secret)
		&.confidential .icon
			background-color var(--color-doc-confidential)
		&.secret .icon
			background-color var(--color-doc-secret)
		.icon
			position absolute
			left 8px
			top 8px
			width 80px
			height 80px
			padding 4px
			background-color red
			.icon-name
				height 16px
				line-height 12px
				text-transform uppercase
				color #fff
				text-align center
				white-space nowrap
				font-size 1.2rem
			.icon-content
				background-color #fff
				height calc(100% - 16px)
				flex flex-start center
				flex-direction column
				padding 4px
				.bar
					width 100%
					height 6px
					margin 0 0 6px 0
					background-color #C3C3C3
					&:nth-child(2)
						height 16px
					&:nth-child(3)
						height 10px
						margin 0
		.title
			height 100%
			flex flex-start center
			margin-left 104px
			text-transform uppercase
			text-align left
			color var(--color-chirp-players-attachment-text)
			font-size 1.6rem
			line-height 20px
	.form
		padding 16px
		color var(--color-chirp-players-form-text)
		&:after
			content ''
			position absolute
			left 16px
			right 16px
			bottom 0
			height 1px
			background-color var(--color-chirp-players-border)
		.title
			height 16px
			margin 0 0 4px 8px
			line-height 16px
			text-transform uppercase
		.input
			&:after
				content ''
				position absolute
				right 0
				top 0
				height 32px
				width 32px
				background url(../../../../assets/img/search-blue-dark.svg) left center no-repeat
				background-size 16px auto
			>>> .ui-input
				height 32px
				background-color #fff
				color dark
				font-size 1.4rem
				padding 0 16px
				border-radius 16px
	> .scroll
		height calc(100% - 84px)
	.player-groups
		padding 16px
		.player-group
			&.expanded
				.player-group-title
					&:after
						content '-'
			.player-group-title
				height 28px
				padding 0 8px
				margin 0 0 4px 0
				background-color var(--color-chirp-primary)
				border-radius 4px
				cursor pointer
				line-height @height
				color var(--color-chirp-bg)
				text-transform uppercase
				font-weight 700
				font-size 1.4rem
				&:after
					content '+'
					position absolute
					right 0
					top 0
					height 28px
					width @height
					line-height @height
					text-align center
					font-size 2rem
			.players
				.player
					padding 8px 0 8px 52px
					min-height 40px
					line-height 20px
					&:last-child
						.player-content
							border none
					.picture
						position absolute
						left 0
						top 8px
						width 40px
						height 40px
						background var(--color-chirp-user-avatar-bg) center center no-repeat
						background-size cover
						border-radius 50%
					.player-content
						padding 0 56px 16px 0
						border-bottom 1px solid var(--color-chirp-players-border)
						.job
							text-transform uppercase
							font-weight 700
							line-height 16px
							margin 0 0 4px 0
						.names
							opacity 0.75
						.action
							position absolute
							right 0
							top 4px
							height 32px
							min-width 32px
							line-height (@height + 1px)
							border-radius 4px
							cursor pointer
							font-size 1.2rem
							font-weight 700
							flex center center
							&.new-message
								background var(--color-chirp-new-conversation-button-bg) //url(../../../../assets/img/new.svg) left 8px bottom 6px no-repeat
								// background-size 20px auto
								color var(--color-chirp-new-conversation-button-text)
								box-shadow 0 0 4px 0 alpha(#000, 25%)
								&:hover
									box-shadow 0 0 4px 2px alpha(#000, 25%)
							&.send-file
								background var(--color-chirp-send-button-bg)
								color var(--color-chirp-send-button-text)
								padding 0 12px
								text-transform uppercase
								&:hover
									background-color var(--color-chirp-send-button-bg-hover)
									color var(--color-chirp-send-button-text-hover)
								&:active
									background-color var(--color-chirp-send-button-bg)
									color var(--color-chirp-send-button-text)
									transform translateY(2px)
								&.sending
									cursor wait
									&:hover
										background var(--color-chirp-send-button-bg)
										color var(--color-chirp-send-button-text)
									&:active
										background var(--color-chirp-send-button-bg)
										color var(--color-chirp-send-button-text)
									.text
										opacity 0
									.loading
										opacity 1
								&.sended
									background-color var(--color-chirp-send-button-bg-sent)
									color var(--color-chirp-send-button-text-sent)
									cursor default
									&:hover
										background-color var(--color-chirp-send-button-bg-sent)
										color var(--color-chirp-send-button-text-sent)
									&:active
										transform none
									.text
										&.default
											display none
										&.success
											display block
								.loading
									opacity 0
									absolute 0
									background url(../../../../assets/img/button-loader.svg) center center no-repeat
									background-size 50% auto
								.text
									&.default
										display block
									&.success
										display none

		
</style>
