import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { cloneDeep } from 'lodash'


// === STATE =====================================================================================
const state = {
	documents: [],
	images: [],
	inventory: [],
	memories: [],
}








// === GETTERS =====================================================================================
const getters = {
	files(state) { return state },
}





// === ACTIONS =====================================================================================
const actions = {


	async load_player_files({ commit, dispatch, rootGetters }) {
		let documents = cloneDeep( await Apollo.query('GET_PLAYER_FILES', { player_id: rootGetters.logged_user.id }) )
		

		let types = [ 'confidential', 'secret', 'top-secret', 'memo', 'image', 'memory', 'inventory' ]
		documents.forEach((doc) => {
			doc.file_type = types[parseInt(doc.file_type)]
			if (doc.file_type === 'image') doc.url = doc.data
		})

		documents = documents.sort((a, b) => {
			if (a.in_game_created === b.in_game_created) return 0
			return a.in_game_created > b.in_game_created ? -1 : 1
		})

		let images = documents.filter(d => d.file_type === 'image')
		documents = documents.filter(d => d.file_type !== 'image')

		let inventory = documents.filter(d => d.file_type === 'inventory')
		documents = documents.filter(d => d.file_type !== 'inventory')

		// inventory.forEach(i => i.read = i.readed)

		// Read documents
		try {
			let reads = []
			if (localStorage.getItem('sre-read-documents')) reads = JSON.parse(localStorage.getItem('sre-read-documents'))
			if (reads.length > 0) {
				documents.forEach((doc) => {
					if (reads.includes(doc.id)) doc.read = true
				})
			}
		} catch(error) {
			console.error(error)
		}
		
		// Read images
		try {
			let reads = []
			if (localStorage.getItem('sre-read-images')) reads = JSON.parse(localStorage.getItem('sre-read-images'))
			if (reads.length > 0) {
				[ ...images, ...inventory ].forEach((item) => {
					if (reads.includes(item.id)) item.read = true
				})
			}
		} catch(error) {
			console.error(error)
		}
		
		// Read inventory
		// try {
		// 	let reads = []
		// 	if (localStorage.getItem('sre-read-images')) reads = JSON.parse(localStorage.getItem('sre-read-images'))
		// 	if (reads.length > 0) {
		// 		inventory.forEach((inv) => {
		// 			if (reads.includes(inv.id)) inv.read = true
		// 		})
		// 	}
		// } catch(error) {
		// 	console.error(error)
		// }

		commit('EDIT_FILES_STATE', { documents, images, inventory })

		// Infobox notifiable document
		let notified = []
		if (localStorage.getItem('sre-notified-documents')) notified = JSON.parse(localStorage.getItem('sre-notified-documents'))
		documents.forEach((doc) => {
			if (doc.notifiable && !notified.includes(doc.id)) {
				const info_box = {
					id: "notifiable-" + doc.id,
					description: "# You received a new document\r\r\rTitle: " + doc.title,
					type: 'document',
					closable: true,
					params: { doc }
				}
				dispatch('add_info_box', { info_box })
				notified.push(doc.id)
				localStorage.setItem('sre-notified-documents', JSON.stringify(notified))
			}
		})
	},


	async share_player_file({ commit, rootGetters }, data) {
		if (data.player_id && data.file_id) {
			try {
				let r = await Apollo.mutate('SHARE_PLAYER_FILE', { player_id: parseInt(data.player_id), file_id: parseInt(data.file_id) })
				return r
			}
			catch (error) {}
			return false
		}
	},


	read_document({ state, commit }, data) {
		commit('EDIT_MEDIA', { media: data.document, read: true })

		try {
			let reads = []
			state.documents.forEach((doc) => { if (doc.read) reads.push(doc.id) })
			localStorage.setItem('sre-read-documents', JSON.stringify(reads))
		} catch(error) {
			console.error(error)
		}
	},


	read_image({ state, commit }, data) {
		commit('EDIT_MEDIA', { media: data.image, read: true })

		try {
			let reads = [];
			[ ...state.images, ...state.inventory ].forEach((img) => { if (img.read) reads.push(img.id) })
			localStorage.setItem('sre-read-images', JSON.stringify(reads))
		} catch(error) {
			console.error(error)
		}
	},


	update_received_files({ commit, rootGetters }) {
		if (rootGetters.logged_user && rootGetters.chirp && rootGetters.chirp.messages.length > 0) {
			let messages = rootGetters.chirp.messages.filter((m) => {
				return m && m.media.length > 0 && m.recipient.id == rootGetters.logged_user.id
			})
			if (messages.length > 0) {
				let received = []
				messages.forEach((message) => {
					message.media.forEach((media) => {
						if (media) {
							if (!received.includes(media)) {
								// if (message.sender && !media.sender) commit('EDIT_MEDIA', { media, sender: message.sender })
								received.push(media)
							}
						}
					})
				})
				commit('EDIT_FILES_STATE', { received })
			}
		}
	}

}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_FILES_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_MEDIA(state, data) {
		for (var i in data) {
			if (i != 'media') Vue.set(data.media, i, data[i])
		}
	}

}

export default { state, getters, actions, mutations }
