import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'


// === STATE =====================================================================================
const state = {
	questions: [],
	dones: []
}



// === ACTIONS =====================================================================================
const actions = {


	async load_outcomes({ commit }, data) {
		if (data.room) {
			const questions = await Apollo.query('ALL_QUESTIONS_BY_SCENARIO', { scenario_id: parseInt(data.room.scenario_id) })
			// console.log(questions)
			// const questions = r.filter((q) => parseInt(q.scenario_id) === parseInt(data.room.scenario_id))

			commit('EDIT_OUTCOME_STATE', {
				questions
			})
		}
	},


	check_outcomes({ state, commit }, data) {
		// console.log('check_outcomes', data.room.timer_total - data.room.timer)
		if (data.room) {

			const questions = state.questions.filter((q) => {
				// if (!state.dones.includes(parseInt(q.id))) console.log(q.timer + q.timeout)
				return q.day === data.room.episode.episode_number && q.timer + q.timeout < data.room.timer_total - data.room.timer && !state.dones.includes(parseInt(q.id)) && (
					(q.question_category === 'CO') ||
					(q.question_category === 'OP' && data.room.questions && data.room.questions.includes(parseInt(q.id)))
				)
			})
			questions.forEach((question) => {
				Apollo.query('GET_POLLING_RESULT', { room_id: parseInt(data.room.id), question_id: parseInt(question.id), calculate: true })
				commit('EDIT_OUTCOME_STATE', { dones: [ ...state.dones, parseInt(question.id) ] })
			})
		}
	},



}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_OUTCOME_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	}

}

export default { state, actions, mutations }
