<template>
	<input :type="type" class="ui-input" @input="updateValue()" :value="value" :placeholder="placeholder" ref="input" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false">
</template>

<script>
export default {
	name: 'ui-input',
	data() {
		return {
			
		}
	},
	props: {
		placeholder: { default: '' },
		type: { default: 'text' },
		value: { default: '' }
	},
	methods: {
		updateValue() {
			this.$emit('input', this.$el.value)
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.ui-input
		font-size 16px
		
.ui-input
	display block
	width 100%
	height 100%
	border none
	background-color transparent
	font inherit
	color inherit
	outline none

</style>
