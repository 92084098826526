import isPlainObject from "is-plain-object"
import Vue from 'vue'


export const wait = (delay = 0) => {
	return new Promise(resolve => setTimeout(resolve, delay))
}

/**
 * Assign a passed function to each object's property
 * @param object
 * @returns {function(*): ({} & {[p: string]: *})}
 */
export const onObjectKeysApply = object => func => {
	return Object.keys(object).reduce((a, key) => {
		return Object.assign(a, {
			[key]: func(object[key])
		})
	}, {})
}

/**
 * Verify if all object's properties have value
 * @param object
 * @param properties
 * @returns {*}
 */
export const isAllPropertiesHaveValue = (object, properties) =>
	object.reduce((a, c) => !!properties[c] && a, true)

/**
 * Assign a starting value to a variable and, after the desired period, assign a ending value
 * N.B. to be called with context
 * @param variable
 * @returns function for curying
 */
export const setTimeoutOn = function (variable) {
	return function (startValue, endValue, nbMilliseconds = 2000) {
		this[variable] = startValue
		setTimeout(() => {
			this[variable] = endValue
		}, nbMilliseconds)
	}.bind(this)
}

/**
 *  Verify, inside the passed object, if there is at least one property that is a plain object
 *  Especially used to limit recursion inside objects properties
 * @param object
 * @returns {boolean}
 */
export const isObjectInside = object =>
	Object.keys(object).reduce(
		(a, key) => isPlainObject(object[key] || a),
		false
	)

/**
 * Set a value to a deep object tree property
 * Filtered "by" passed function
 * @param tree
 * @param value
 * @param by
 * @returns {*}
 */
export const setTreeDeepValueBy = (tree, value, by = x => false) => tree
	.reduce((a, c) => {
		if (c.subjectsToH && c.subjectsToH.length) {
			return [
				...a,
				Object.assign(c, {
					active: by(c) && value,
					subjectsToH: setTreeDeepValueBy(c.subjectsToH, value, by)
				})
			]
		}
		return [
			...a,
			Object.assign(c, {
				active: by(c) && value
			})
		]
	}, [])

/**
 * Delete a deep object property
 * Filtered "by" passed function
 * @param tree
 * @param by
 * @returns {*}
 */
export const deleteTreeDeepNodeBy = (tree, by) => tree
	.reduce((a, c) => {
		if (by(c)) {
			return a
		}
		if (c.subjectsToH && c.subjectsToH.length) {
			return [
				...a,
				Object.assign(c, {
					subjectsToH: deleteTreeDeepNodeBy(c.subjectsToH, by)
				})
			]
		}
		return [...a, c]
	}, [])

export const stackStringnify = x => JSON.stringify(x)

/**
 * Convert plain identifier to Stack Identifier Format
 * Presently kebab case
 * @param x
 * @returns {string}
 */
export const stackIdentifier = x => Vue._.kebabCase(x)

/**
 * Extract data from schema
 * @param schema
 * @returns {{} & {[p: string]: *}}
 */
export const extractDataFromScheme = schema => Object.keys(schema)
	.reduce((a, key) => Object.assign(a, {
		[key]: schema[key].value
	}), {})

export const isBetweenEqual = (value = value.toString(), min, max) => value >= min && value <= max
export const isBetween = (value = value.toString(), min, max) => value > min && value < max

/**
 * Add properties to each object of array, to permit use in draggable nested tree view
 * properties : option_text, option_id and children (children allows nesting)
 * @param x
 */
export const convertToDraggableNestedTreeView = array => {
	if (!Array.isArray(array)) return array
	return array
		.map(x => Object.assign(x, {
			option_text: x.slug,
			option_id: x.id,
			children: []
		}))
}

/**
 * Returns only options text & id for dropdown select from array of objects
 * @param array
 * @returns {*}
 */
export const convertToDropdown = (array, {text, id, tooltip = false, transform = null} = {}) => {
	if (!Array.isArray(array)) return array
	return array
		.map(x => {
			const result = {
				option_text: text ? x[text] : (x.slug || x),
				option_id: id ? x[id] : (x.id || x),
				tooltip: tooltip ? x.html : ''
			}
			return transform ? transform(result) : result
		})
}

export const transformConditions = object => result => ({
	option_text: (x => {
		return Object.keys(object)
			.reduce((a, key) => (a.includes(key) ? `${a.split(key).shift().replace('_', '')} ${object[key]}` : a), result.option_text.toLowerCase())
	})(result),
	option_id: result.option_id,
	tooltip: result.option_text
})


/**
 * Assign properties to a given object with variables object
 * variables is a named arguments object, constituted by variables
 * @param object
 * @param variables
 * @returns {*}
 */
export const assignPropertiesToObjectOnlyWhenVarHasValues = (object, variables) => {
	const clone = Vue._.cloneDeep(object)
	Object.keys(variables)
		.forEach(key => {
			if (
				!Vue._.isEmpty(variables[key])
				|| Array.isArray(variables[key])
			) {
				Object.assign(clone, {
					[key]: variables[key]
				})
			}
		})
	return clone
}

/**
 * GraphQL related
 * To enclose all variables passed to mutations in a 'input' property
 * @param data
 * @returns {{input: *}}
 */
export const encloseInInput = data => ({input: data})

/**
 * Check if x, converted as String, is a html content, then returns true/false
 * @param x
 * @returns {boolean}
 */
export const isHTML = x => /<[a-z][\s\S]*>/i.test(String(x))

/**
 * check if String (contentType syntax) is image
 * @param x
 * @returns {boolean}
 */
export const isImage = x => /^image\/.*$/.test(String(x))

/**
 * check if String (contentType syntax) is JSON
 * @param x
 * @returns {boolean}
 */
export const isJSON = x => /^.*\/json$/.test(String(x))

/**
 * check if String (contentType syntax) is SVG
 * @param x
 * @returns {boolean}
 */
export const isSVG = x => /^.*svg.*$/.test(String(x))

/**
 * check if object has a value at the end of the submitted dot syntax path
 * @param object
 * @param path
 * @returns {boolean}
 */
export const isValueOnPath = (object = {}, path = []) => !!path.split('.').reduce((a, c) => (a && a[c] ? a[c] : false), object)

/**
 * get the value at the end of object's path
 * @param object
 * @param path
 * @returns {*}
 */
export const getValueOnPath = (object = {}, path = []) => path.split('.').reduce((a, c) => (a && a[c] ? a[c] : null), object)

/**
 * Purify object of any __typename property and get filtered result (i.e. 'data' or 'content' property) to assign it at a lower level
 * @param object
 * @returns {{__typename}|*}
 */
export const purify = object => {
	if (!object) return object
	const dataFilter = x => x.data ? x.data : x
	const contentFilter = x => x.content ? x.content : x
	const captureFilters = {
		step: contentFilter,
		steps: dataFilter,
		questions: dataFilter,
		answers: dataFilter,
		clientMedias: dataFilter,
		clientRewards: dataFilter,
		clientGames: dataFilter,
		contentTexts: dataFilter,
		clientCriterias: dataFilter,
		children: dataFilter,
		thresholds: dataFilter
	}
	const get = (object, property, filters) => {
		return (filters[property] || (x => x))(object)
	}
	for (let key in object) {
		object[key] = get(object[key], key, captureFilters)
		if (
			isPlainObject(object[key])
		) {
			object[key] = purify(object[key])
		} else if (
			_.isArray(object[key])
		) {
			object[key] = object[key].map(x => (isPlainObject(x) ? purify(x) : x))
		}
	}
	if (object.__typename) delete object.__typename
	return object
}

/**
 * Custom array manipulation functions to increase performance
 * @type {{pop: (function(*): *), lastIndexOf: (function(*, *): *), unshift: stackArray.unshift, spliceOne: stackArray.spliceOne, indexOf: stackArray.indexOf, push: (function(*, *): *)}}
 */
export const stackArray = {
	push: (array, value) => array[array.length] = value,
	pop: array => array[array.length--],
	unshift: (array, value) => {
		let len = array.length
		while (len) {
			array[len] = array[len - 1]
			len--
		}
		array[0] = value
	},
	spliceOne: (array, index) => {
		let len = array.length
		if (!len) return
		while (index < len) {
			array[index] = array[++index]
		}
		array.length--
	},
	indexOf: (array, value) => {
		for (let x = 0, len = array.length; x != len; x++) {
			if (array[x] === value) return x
		}
		return -1
	},
	lastIndexOf: (array, value) => {
		let index = array.length
		while (index--) {
			if (array[index] === value) break
		}
		return index
	}
}

/**
 * Returns empty value depending on passed type
 * @param type
 * @returns {*|null}
 */
export const getEmptyValueByType = ({ type }) => {
	return {
		'Integer': 0,
		'Float': 0,
		'String': '',
		'Object': {},
	}[type] || null
	
}

/**
 * Assign deep path property value to object
 * @param object
 * @param path
 * @param value
 * @returns {any}
 * @constructor
 */
export const VueSetDeepProperty = ({ object = {}, path = '', value }) => {
	const pathArray = path.split('.')
	const property = pathArray.shift()
	return Object.assign(object, {
		[property]: pathArray.reverse().reduce((a, c) => ({ [c]: a }), value)
	})
}



/**
 * Transforms a string into a slug
 * @param String
 */
export const slugify = (str) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')

	return str.toLowerCase()
		.replace(/\s+/g, '')
		.replace(p, c => b.charAt(a.indexOf(c)))
		.replace(/&/g, '')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '')
		.replace(/^-+/, '')
		.replace(/-+$/, '')
};

/**
 * Returns the array's correct index when receiving negative or positive index.
 * Usage : When you want a circular index. Given index do never exceed the boundary, but go to the opposite end.
 * @param negativeOrPositiveIndex
 * @param arrayLength
 * @returns {number}
 */
export const getCircularIndex = (negativeOrPositiveIndex, arrayLength) => (arrayLength + (negativeOrPositiveIndex % arrayLength)) % arrayLength

/**
 * Generate Unique Random ID of approximately 20 characters
 * @returns {string}
 */
export const getRandomID = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

/**
 * Returns passed base64Data's content type
 * @param base64Data
 * @returns {void | any | string}
 */
export const getBase64ContentType = base64Data => base64Data.match(/:(.*);/).pop() || ''

/**
 * Returns passed base64Data's data
 * @param base64Data
 * @returns {void | any}
 */
export const getBase64Data = base64Data => base64Data.split(',').pop() || base64Data
/**
 * Returns Blob from base64
 * @param base64Data
 * @param filename
 * @returns {File|null}
 */
export const base64toFile = base64Data => {
	const type = getBase64ContentType(base64Data)
	const data = getBase64Data(base64Data)
	if (!data) return null
	const byteCharacters = atob(data)
	let byteCharactersLength = byteCharacters.length
	const u8array = new Uint8Array(byteCharactersLength)
	while (byteCharactersLength) {
		u8array[byteCharactersLength] = byteCharacters.charCodeAt(byteCharactersLength - 1)
		byteCharactersLength -= 1
	}
	const filename = 'imageInHtml'
	return new File([u8array], filename, { type })
}

/**
 * Returns Blob from Base 64
 * @param base64Data
 * @param sliceSize
 * @returns {Blob}
 */
export const base64toBlob = (base64Data, sliceSize = 1024) => {
	const type = getBase64ContentType(base64Data) || ''
	const data = getBase64Data(base64Data)
	let byteCharacters = atob(data)
	let byteArrays = []
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		let slice = byteCharacters.slice(offset, offset + sliceSize)
		let byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}
		let byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}
	return new Blob(byteArrays, {type})
}

/**
 * Returns true/false if x is an even number
 * @param x
 * @returns {boolean}
 */
export const is_even = x => Number.isInteger(x) ? x % 2 === 0 : x

/**
 * Returns true/false if x is an odd number
 * @param x
 * @returns {boolean}
 */
export const is_odd = x => Number.isInteger(x) ? x % 2 === 1 : x

/**
 * Returns array of array's values, positioned at rest indexes
 * @param array
 * @param rest
 * @returns {*}
 */
export const valuesAt = (array, ...rest) => Array.isArray(array) ? rest.reduce((a, c) => [...a, array[c]], []) : array

/**
 * Returns new array with elements inserted in the clone of the passed array at index position
 * Values can be either series of arguments or an array
 * @param array
 * @param index
 * @param rest
 * @returns {*[]}
 */
export const insertAt = (array, index = 0, ...rest) => {
	if (!Array.isArray(array)) return array
	let clone = [...array]
	clone.splice(index, 0, ...rest.flat())
	return clone
}

/**
 * Returns last n elements of array
 * @param array
 * @param howMany
 * @returns {*[]}
 */
export const arrayLast = (array = [], n = 1) => Array.isArray(array) ? [...array].slice(array.length - n) : array

/**
 * Returns first n elements of rray
 * @param array
 * @param n
 * @returns {*[]}
 */
export const arrayFirst = (array = [], n = 1) => Array.isArray(array) ? [...array].slice(0, n) : array

/**
 * Returns sorted array of object, based on order for specific property
 * @param array
 * @param order
 * @param property
 * @returns {*[]}
 */
export const sortArrayWithOrdersOnProperty = (array = null, order = null, property = null) => {
	if (!array || !order || !property) return
	const object = order.reduce((a, c) => Object.assign(a, { [c]: array.filter(x => x[property] === c) }), [])
	object.rest = array.filter(x => !order.includes(x[property]))
	return Object.keys(object).reduce((a, c) => [...a, ...object[c]], [])
}

/**
 * Returns true/false if at least one item of search is in array
 * @param array
 * @search array
 * @returns {boolean}
 */
export const includesAtLeastOneItemInArray = (array = null, search = null) => {
	if (!array || !search) return
	return array.filter((s) => { return search.indexOf(s) > -1 }).length > 0
}

export const random = (max, min=0) => {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min + 1)) + min
}

export const sample = (arr) => {
	if (arr.length >= 2) {
		let n = random(arr.length-1)
		return arr[n]
	} else
		return arr[0]
}


export const shuffle_property = (arr, property) => {
	let remaining = []
	arr.forEach((el) => { remaining.push(el[property]) })

	let els = []
	arr.forEach((el) => {
		if (remaining.length) {
			const p = sample(remaining)
			el[property] = p
			els.push(el)
			const index = remaining.findIndex((r) => { return r === p })
			if (index > -1) remaining.splice(index, 1)
		}
	})
	return els
}

export const throttle = (callback, delay) => {
	var last
	var timer
	return function () {
		var context = this
		var now = +new Date()
		var args = arguments
		if (last && now < last + delay) {
			// le délai n'est pas écoulé on reset le timer
			clearTimeout(timer)
			timer = setTimeout(function () {
				last = now
				callback.apply(context, args)
			}, delay)
		} else {
			last = now
			callback.apply(context, args)
		}
	}
}


export function debounce(callback, delay){
	var timer
	return function(){
		var args = arguments
		var context = this
		clearTimeout(timer)
		timer = setTimeout(function(){
			callback.apply(context, args)
		}, delay)
	}
}


export function get_initials(name) {
	return name.split(" ").map((n)=>n[0]).join("").toUpperCase()
}