import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app'
import user from './modules/user'
import game from '@/store/modules/game/game'
import chirp from '@/store/modules/game/chirp'
import files from '@/store/modules/game/files'
import progress from '@/store/modules/game/progress'
import outcome from '@/store/modules/outcome'
import master from '@/store/modules/master/master'

const state = {
	
}

const store = new Vuex.Store({
	state,
	modules: {
		app,
		user,
		game,
		chirp,
		files,
		progress,
		master,
		outcome,
	},
	strict: process.env.NODE_ENV !== 'production'
})


export default store
