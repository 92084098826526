<template>
	<div class="chirp-home">
		<div class="scroll" v-scrollbar>
			<div>
				<div class="chirp-content-wrap">
					<chirp-item v-for="chirp_item in chirps" :chirp_item="chirp_item" :key="chirp_item.id" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChirpItem from '@/components/game/chirp/elements/chirp-item'
import { slugify } from '@/js/utils/utils'

export default {
	name: 'chirp-home',
	data() {
		return {
			
		}
	},
	props: {
		search: { default: '' }
	},
	computed: {
		...mapGetters([ 'logged_user', 'chirp' ]),
		chirps() {
			let feed = this.chirp.feed
			if (this.search) {
				let words = this.search.trim().split(' ')
				let slugs = []
				for (let i in words) {
					let slug = slugify(words[i])
					if (slug && slug.length > 1) slugs.push(slug)
				}
				let regex = new RegExp(slugs.join('|'))
				feed = feed.filter((c) => {
					return slugify(c.message).toLowerCase().match(regex) 
				})
			}
			return feed.concat().sort((a, b) => {
				if (a.created > b.created) return -1
				return 1
			})
		}
	},
	components: { ChirpItem }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.chirp-home
	height 100%
		
</style>
