<template>
	<div v-if="chirp.current.media_viewer && chirp.current.media_viewer.chirp_item" class="chirp-media-viewer">
		<div class="chirp-media-viewer-content">

			<hooper class="slider-main" group="group" ref="carousel">
				<slide v-for="(url, index) in chirp.current.media_viewer.chirp_item.media" :key="index" :id="'file-chirp-' + index">
					<div class="img" v-fancybox="{ src: url }" :data-src="url" data-gallery="chirp-viewer" v-bg="{ url }"></div>
				</slide>
			</hooper>

			<div class="separator"></div>
			
			<hooper class="slider-bullets" group="group" :itemsToShow="nb_bullets" :centerMode="true">
				<slide v-for="(media, index) in chirp.current.media_viewer.chirp_item.media" :key="index" v-hammer:tap="() => slideTo(index+1)">
					<div class="img" v-bg="{ url: media }"></div>
				</slide>
				<hooper-navigation slot="hooper-addons"></hooper-navigation>
			</hooper>

		</div>
		<div class="close" v-hammer:tap="() => close()"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'chirp-media-viewer',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'app', 'chirp' ]),
		nb_bullets() {
			if (this.app.device == 'mobile') return 4
			if (this.app.device == 'tablet') return 5
			return 6
		}
	},
	watch: {
		'chirp.current.media_viewer.media'(newValue, oldValue) {
			this.slide_to_current_media()
		}
	},
	methods: {
		slide_to_current_media() {
			let slide = this.$el.querySelector('#file-chirp-' + this.chirp.current.media_viewer.media.id)
			if (slide) {
				let index = [].indexOf.call(slide.parentElement.children, slide)
				if (index > -1) this.$refs.carousel.slideTo(index)
			}
		},
		slideTo(n) {
			this.$refs.carousel.slideTo(n-1)
		},
		close() {
			this.$store.dispatch('set_chirp_current', { media_viewer: null })
		}
	},
	mounted() {
		if (this.chirp.current.media_viewer && this.chirp.current.media_viewer.media) {
			this.slide_to_current_media()
		}
	},
	components: { Hooper, Slide, HooperNavigation }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal
	.chirp-media-viewer
		left 8px
		top 8px
		width calc(100% - 16px)
		height calc(100% - 16px)
		padding 16px 0 0 0
		box-shadow 0 0 0 8px blue_dark
		.chirp-media-viewer-content
			padding 0
			.slider-main
				.hooper-slide
					padding 0 8px
			.hooper
				.hooper-navigation
					.hooper-prev
						left 8px
					.hooper-next
						right 8px
		.close
			top 16px
			right 16px


.chirp-media-viewer
	position absolute
	left -600px
	top 0
	bottom 0
	height 100%
	width 600px
	user-select none
	padding 72px 0
	padding 16px 0 72px 0
	background-color var(--color-image-viewer-bg)
	box-shadow inset -1px 0 4px 0 alpha(#000, 25%), -1px 0 4px 0 alpha(#000, 25%)
	.chirp-media-viewer-content
		padding 0 40px
		height 100%
		.slider-main
			height calc(100% - 180px)
			.hooper-slide
				padding 0 16px
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size contain
					cursor pointer
		.separator
			height 18px
			margin 16px 0
			border-top 2px solid var(--color-image-viewer-border)
			&:after
				content ''
				position absolute
				left calc(50% - 10px)
				top 0
				width 0
				height 0
				border-style solid
				border-width 10px 10px 0 10px
				border-color var(--color-image-viewer-border) transparent transparent transparent
		.slider-bullets
			height 100px
			.hooper-slide
				text-align center
				padding 0 4px
				cursor pointer
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
	.close
		position absolute
		right 32px
		top 32px
		width 48px
		height 48px
		border-radius 50%
		background #fff url(../../../../assets/img/close-blue-dark.svg) center center no-repeat
		background-size 16px auto
		box-shadow 0 2px 4px alpha(#000, 50%)
		cursor pointer
		
>>> .hooper-slide
	text-align center
	.img
		width 100%
		height 100%
		background center center no-repeat
		background-size cover

>>> .hooper
	outline none
	.hooper-navigation
		position absolute
		left 0
		top 50%
		right 0
		height 0
		.hooper-prev, .hooper-next
			padding 0
			&:before
				content ''
				position absolute
				top 0
				width 0
				height 0
				border-style solid
				padding 0
			&.hooper-prev
				&:before
					left 8px
					border-width 12px 16px 12px 0
					border-color transparent var(--color-image-viewer-border) transparent transparent
			&.hooper-next
				&:before
					right 8px
					border-width 12px 0 12px 16px
					border-color transparent transparent transparent var(--color-image-viewer-border)
			svg
				opacity 0

#app.ipad-theme
	.chirp-media-viewer
		fixed 0
		top 0
		bottom 80px
		width auto
		height auto
		background none
		backdrop-filter blur(10px)
		&:before
			content ''
			absolute 0
			background-color var(--color-image-viewer-bg)
			opacity 0.9

</style>
