<template>
	<div class="video-player">
		<div class="bg"></div>
		<div v-if="src" class="content">
			<iframe v-if="is_iframe" ref="iframe" :src="src" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			<ui-video v-else :src="src" :light="light" :autoplay="autoplay" :muted="muted" />
		</div>
		<div class="close" :class="{ visible: close_visible }" v-hammer:tap="() => close()"></div>
		<div class="muted-button" :class="{ visible: close_visible }" v-hammer:tap="() => toggle_muted()">
			<svg v-if="muted" key="1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume-x"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><line x1="23" y1="9" x2="17" y2="15"></line><line x1="17" y1="9" x2="23" y2="15"></line></svg>
			<svg v-if="!muted" key="2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume-2"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path></svg>
		</div>
		<div class="overlay" v-show="!close_visible" @mousemove="move()"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiVideo from '@/components/ui/ui-video'

export default {
	name: 'video-player',
	data() {
		return {
			muted: true,
			close_visible: false
		}
	},
	props: {
		slug: { default: null },
		light: { default: true },
		autoplay: { default: true }
	},
	computed: {
		...mapGetters([ 'game' ]),
		url() {
			// return "https://s3.amazonaws.com/www.ithrivesim.org/assets/videos/Inciting_Incident+(CAPTIONED).mp4"
			// return 'https://s3.amazonaws.com/www.ithrivesim.org/assets/videos/Inciting_Incident+(CAPTIONED).mp4'
			// return https://vimeo.com/460361008/ec949603dc => tuto
			// return 'https://drive.google.com/file/d/1mlJi-ic3ID-ivCYE-PlrR4rpLJg6rWHD/view?usp=sharing'
			if (this.slug) {
				if (this.game.room.episode[this.slug]) return this.game.room.episode[this.slug]
				if (this.game.scenario[this.slug]) return this.game.scenario[this.slug]
			}
			return null
		},
		is_iframe() {
			return (this.url && this.url.toLowerCase().indexOf('vimeo') > -1)
		},
		src() {
			if (this.url) {
				if (this.is_iframe) {
					const reg = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
					const match = this.url.match(reg)
					if (match && match.length > 3) {
						return 'https://player.vimeo.com/video/' + match[3] + '?autoplay=1&title=0&byline=0&portrait=0'
					}
				} else {
					const reg = /https?:\/\/drive\.google\.com\/[a-zA-Z=\/?&]+=?([a-z0-9A-Z-]+)/
					const match = this.url.match(reg)
					if (match && match.length > 1) {
						return 'https://drive.google.com/uc?export=download&id=' + match[1]
					}
				}
			}
			return this.url
		}
	},
	watch: {
		close_visible() {
			setTimeout(() => {
				this.close_visible = false
			}, 3000)
		}
	},
	methods: {
		move() {
			this.close_visible = true
			// console.log(e)
		},
		toggle_muted() {
			this.muted = !this.muted
			this.move()
		},
		close() {
			this.$store.commit('EDIT_GAME_STATE', { playing_video: null })
		}
	},
	mounted() {
		// document.addEventListener('mousemove', e => {
		// 	console.log(e)
		// })
	},
	components: { UiVideo }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.video-player
	absolute 0
	flex center center
	z-index 3
	&.video-player-active
		transition 0.5s easeOutQuart
	&.video-player-enter .close
		transform translateY(-96px)
	.bg
		absolute 0
		background-color dark
	.content
		width 100%
		height 100%
		// max-width 640px
		// max-height 640px
	.overlay
		absolute 0
		// background-color alpha(red, 50%)
	.close
		position absolute
		left calc(50% - 40px)
		top 8px
		width 80px
		height 80px
		background url(../../assets/img/close-round-white.svg) center center no-repeat
		background-size contain
		cursor pointer
		transition 0.5s easeOutQuart
		transform translateY(-100%)
		opacity 0
		&.visible
			transform translateY(0)
			opacity 1
		&:active
			transform translateY(4px) scale(0.9)
			opacity 1
	.muted-button
		position absolute
		left calc(50% - 40px)
		bottom 32px
		width 64px
		height 64px
		background #fff
		box-shadow 0 0 8px #000
		cursor pointer
		transition 0.5s easeOutQuart
		transform translateY(100%)
		opacity 0
		border-radius 50%
		flex center center
		color #333
		&.visible
			transform translateY(0)
			opacity 1
		&:active
			transform translateY(4px) scale(0.9)
			opacity 1
		>>> svg
			width 32px
			height 32px
		
</style>
