<template>
	<div class="chirp-media-selection">

		<div class="close" v-hammer:tap="() => close()"><ui-icon name="close" :size="16" /></div>

		<div class="head">
			<div class="tabs">
				<div class="tab" :class="{ active: active_tab == 'images' }" v-hammer:tap="() => set_tab('images')">Images</div>
				<div v-if="chirp.route.name !== 'feed'"class="tab" :class="{ active: active_tab == 'documents' }" v-hammer:tap="() => set_tab('documents')">Documents</div>
			</div>
		</div>

		<div class="content">
			<div v-if="active_tab == 'documents'" class="tab-content">
				<div class="scroll" v-scrollbar>
					<div>
						<div class="documents">
							<div v-for="doc in files.documents" class="document" :class="[ doc.file_type, { selected: selected_media.includes(doc.id) }, (sending.find((s) => s.player_id === chirp.route.params.player.id && s.file_id === doc.id) || { state: '' }).state ]" :key="doc.id" v-hammer:tap="() => toggle_select(doc)">
								<div class="content">
									<div class="type">{{ doc.file_type | file_type_to_str }}</div>
									<div class="title">{{ doc.title }}</div>
									<div class="success" title="Sended">Sent</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="active_tab == 'images'" class="tab-content">
				<div class="scroll" v-scrollbar>
					<div>
						<div class="medias">
							<div v-for="media in files.images" class="media" :class="{ selected: selected_media.includes(media.id) }" :key="media.id" v-hammer:tap="() => toggle_select(media)">
								<div class="img" v-bg="media"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="slide-up">
			<div v-show="selected_media.length > 0" class="send" :class="{ loading }" v-hammer:tap="() => send()">
				{{ chirp.route.name === 'feed' ? "Attach" : "Send" }} <span class="nb">{{ selected_media.length }}</span>
				<div v-if="loading" class="loader">
					<ui-icon name="button-loader" :size="40" />
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'chirp-media-selection',
	data() {
		return {
			sending: [],
			loading: false,
			active_tab: 'images',
			selected_media: []
		}
	},
	computed: {
		...mapGetters([ 'chirp', 'files' ])
	},
	filters: {
		file_type_to_str(value) {
			if (value == 'confidential') return "Confidential"
			if (value == 'secret') return "secret"
			if (value == 'top-secret') return "Top secret"
			if (value == 'memo') return "Memo"
			return value
		}
	},
	watch: {
		'chirp.route.params.player'() {
			this.close()
			this.loading_sending()
		}
	},
	methods: {
		set_tab(tab) {
			this.active_tab = tab
		},
		toggle_select(image) {
			let index = this.selected_media.indexOf(image.id)
			if (index > -1)
				this.selected_media.splice(index, 1)
			else
				this.selected_media.push(image.id)
		
			// if (!this.chirp.active.media_selection_multiple) this.send()
		},
		close() {
			this.$store.dispatch('set_chirp_current', { media_selection_visible: false, selected_media: [] })
		},
		async send() {
			if (this.selected_media.length > 0 && !this.loading) {
				this.loading = true

				if (this.chirp.route.name === 'feed') {
					this.$store.dispatch('set_chirp_current', { media_selection_visible: false, selected_media: this.selected_media.map(id => {
						return this.files.images.find((i) => i.id === id)
					}) })
				} else {
					this.selected_media.forEach(async (id) => {
						let type = 'document'
						let media = this.files.documents.find((d) => d.id === id)
						if (!media) {
							media = this.files.images.find((i) => i.id === id)
							type = 'image'
						}

						if (media) {

							// Document
							if (type === 'document'/*media.file_type && ['confidential', 'secret', 'top-secret', 'memo'].includes(media.file_type)*/) {
								let shared = await this.$store.dispatch('share_player_file', { player_id: this.chirp.route.params.player.id, file_id: media.id })
								if (shared) {
									let message_id = await this.$store.dispatch('send_message', {
										title: '',
										message: "File \"" + media.title + "\" shared",
										recipient: this.chirp.route.params.player,
										channel: '',
										tags: [],
										message_type: 'Chirp',
										medias: []
									})
									if (message_id) {
										const sended = { player_id: this.chirp.route.params.player.id, file_id: media.id, state: 'sended' }
										let items = localStorage.getItem('sre-sended-files')
										if (items) items = JSON.parse(items)
										if (!items) items = []
										items.push(sended)
										localStorage.setItem('sre-sended-files', JSON.stringify(items))
									}
								}
							}

							// Image
							else if (type === 'image' && media.url) {
								await this.$store.dispatch('send_message', {
									title: '',
									message: ' ',
									recipient: this.chirp.route.params.player,
									tags: [],
									message_type: 'Chirp',
									medias: [ media.url ]
								})
							}
						}
					})
					

					setTimeout(() => {
						this.loading = false
						this.close()
					}, this.selected_media.length * 500)
				}

			}
		},
		loading_sending() {
			this.sending = []
			if (this.chirp.route.params.player) {
				let items = localStorage.getItem('sre-sended-files')
				if (items) {
					items = JSON.parse(items)
					this.sending = items.filter((i) => parseInt(i.player_id) === parseInt(this.chirp.route.params.player.id))
				}
			}
		}
	},
	mounted() {
		this.loading_sending()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


// #app.mobile
// 	.chirp-media-selection
// 		.content
// 			height calc(100% - 48px)


.chirp-enter-active, .chirp-leave-active
	transition 0.15s easeOutQuart
.chirp-enter, .chirp-leave-to
	transform translateX(24px)
	opacity 0


.chirp-media-selection
	position absolute
	right 100%
	top 0
	bottom 0
	width 380px
	background-color var(--color-chirp-media-selection-bg)
	box-shadow -4px 0 4px alpha(#000, 35%)
	.head
		height 48px
		margin-top 64px
		// background-color chirp
		padding 8px 16px
		.tabs
			flex center center
			height 32px
			.tab
				display inline-block
				height 100%
				flex center center
				width 50%
				font-weight 700
				font-size 1.6rem
				text-transform uppercase
				box-shadow inset 0 0 0 2px var(--color-chirp-media-selection-tab-border)
				background-color var(--color-chirp-media-selection-tab-bg)
				cursor pointer
				color var(--color-chirp-media-selection-tab-text)
				&:first-child
					border-top-left-radius 16px
					border-bottom-left-radius 16px
				&:last-child
					border-top-right-radius 16px
					border-bottom-right-radius 16px
				&.active
					background-color var(--color-chirp-media-selection-tab-active-bg)
					color var(--color-chirp-media-selection-tab-active-text)
	.content
		height calc(100% - 112px)
		.tab-content
			height 100%
		.documents
			padding 8px 24px
			.document
				padding 8px
				margin 0 0 8px 0
				text-align center
				color dark
				cursor pointer
				&.memo
					background-color var(--color-doc-memo)
					.type
						color var(--color-doc-memo)
				&.top-secret
					background-color var(--color-doc-top-secret)
					.type
						color var(--color-doc-top-secret)
				&.confidential
					background-color var(--color-doc-confidential)
					.type
						color var(--color-doc-confidential)
				&.secret
					background-color var(--color-doc-secret)
					.type
						color var(--color-doc-secret)
						
				&.sended
					.success
						display block
						position absolute
						right 0
						top 0
						height 24px
						padding 0 8px 0 24px
						background var(--color-chirp-media-selection-send-button-bg-sent) url(../../../../assets/img/check-white.svg) left 6px center no-repeat
						background-size 16px
						// border-radius 24px
						line-height @height
						color var(--color-chirp-media-selection-send-button-text-sent)
						text-transform uppercase
						font-weight 700
						font-size 1.2rem
				
				&.selected
					&:after
						content ''
						absolute 0
						background-color var(--color-chirp-media-selection-file-selected-bg)
						box-shadow inset 0 0 0 3px var(--color-chirp-media-selection-file-selected-border)

				.content
					padding 8px
					background-color #fff
					.type
						text-transform uppercase
						font-weight 700
						font-size 1.6rem
				.success
					display none
					
		.medias
			flex center center
			flex-wrap wrap
			padding 0 8px 64px 8px
			.media
				display inline-block
				width 96px
				height 96px
				margin 8px
				background-color var(--color-chirp-media-selection-media-bg)
				cursor pointer
				&.selected
					&:after
						content ''
						absolute 0
						background-color var(--color-chirp-media-selection-file-selected-bg)
						box-shadow inset 0 0 0 3px var(--color-chirp-media-selection-file-selected-border)
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
	.close
		position absolute
		left calc(50% - 24px)
		top 16px
		width 48px
		height 48px
		border-radius 50%
		cursor pointer
		flex center center
		color var(--color-chirp-media-selection-close-color)
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
	.send
		position absolute
		left 50%
		bottom 16px
		height 48px
		padding 0 24px
		transform translateX(-50%)
		border-radius @height
		background var(--color-chirp-media-selection-send-button-bg)
		box-shadow 0 0 4px 2px alpha(#000, 50%)
		color var(--color-chirp-media-selection-send-button-text)
		text-transform uppercase
		font-size 1.6rem
		line-height @height
		font-weight 700
		cursor pointer
		flex center center
		&.loading
			cursor wait
			.loader
				absolute 2px
				background var(--color-chirp-media-selection-send-button-loading-bg)
				color var(--color-chirp-media-selection-send-button-loading-text)
				border-radius 40px
				flex center center
		.nb
			display inline-block
			width 24px
			height 24px
			margin 0 0 0 4px
			border-radius 50%
			background-color var(--color-chirp-media-selection-send-button-text)
			color var(--color-chirp-media-selection-send-button-bg)
			text-align center
			font-weight 400
			line-height (@height + 2px)

#app.ipad-theme
	.chirp-media-selection
		fixed 0
		top 0
		bottom 80px
		width auto
		height auto
		background none
		backdrop-filter blur(10px)
		&:before
			content ''
			absolute 0
			background-color var(--color-image-viewer-bg)
			opacity 0.9
		.head, .content
			max-width 800px
			margin-left auto
			margin-right auto
			
</style>
