import Vue from 'vue'
import VueRouter from 'vue-router'
import Apollo from '@/apollo/apollo-client'
import store from '@/store/store'
import Pulling from '@/js/pulling'
import { Medias } from '@/js/utils/medias'

Vue.use(VueRouter)

const routes = [
	{
		path: '/game',
		name: 'game',
		component: () => import('@/views/view-game.vue'),
		children: [
			{
				path: 'files',
				name: 'files',
				component: () => import('@/views/game/view-files.vue'),
				children: [
					{
						path: 'notes',
						name: 'files-notes'
					},
					{
						path: ':category',
						name: 'files-category',
						children: [
							{
								path: ':file_id',
								name: 'file-viewer',
							}
						]
					}
				]
			},
			{
				path: 'stats',
				name: 'stats',
				component: () => import('@/views/game/view-stats.vue')
			},
			{
				path: 'chirp',
				name: 'chirp',
				children: [
					{
						path: 'feed',
						name: 'chirp-feed'
					},
					{
						path: 'messages',
						name: 'chirp-messages'
					}
				]
			},
			{
				path: 'news',
				name: 'news',
				component: () => import('@/views/game/view-news.vue'),
				children: [
					{
						path: 'post',
						name: 'news-post',
					}
				]
			},
			{
				path: 'decisions',
				name: 'decisions',
				component: () => import('@/views/game/view-decisions.vue'),
				children: [
					{
						path: ':decision',
						name: 'decision'
					}
				]
			},
			{
				path: 'profile',
				name: 'profile',
				component: () => import('@/views/game/view-profile.vue'),
				children: [
					{
						path: ':category',
						name: 'profile-detail',
						children: [
							{
								path: ':npc_id(\\d+)',
								name: 'profile-detail-npc',
							},
							{
								path: ':attribute_slug(authority|transparency|experience|ambition|loyalty)',
								name: 'profile-detail-attribute',
							}
						]
					}
				]
			},
			{
				path: 'goals',
				name: 'goals',
				component: () => import('@/views/game/view-goals.vue')
			},
			{
				path: 'summary',
				name: 'summary',
				component: () => import('@/views/game/view-summary.vue')
			},
		]
	},
	{
		path: '/login-master',
		name: 'login-master',
		component: () => import('@/views/view-master-login.vue'),
	},
	{
		path: '/(selling-dreams|disastermind|jane|ctec)',
		name: 'quick-game',
		component: () => import('@/views/view-quick-game.vue'),
	},
	{
		path: '/master',
		name: 'master',
		component: () => import('@/views/view-master.vue'),
		children: [
			{
				path: 'rooms',
				name: 'rooms',
				component: () => import('@/views/master/view-rooms.vue'),
				children: [
					{
						path: 'add',
						name: 'new-room',
						meta: {
							window: {
								name: 'new-room',
								max_width: 450
							}
						}
					},
					{
						path: 'start-multiple/:room_ids',
						name: 'start-multiple',
						meta: {
							window: {
								name: 'start-multiple',
								max_width: 450
							}
						}
					},
					{
						path: 'delete/:room_ids',
						name: 'delete-rooms',
						meta: {
							window: {
								name: 'delete-multiple-rooms',
								max_width: 450
							}
						}
					},
					{
						path: ':room_id',
						name: 'room',
						children: [
							{
								path: 'roles',
								name: 'room-roles',
								children: [
									{
										path: 'validate',
										name: 'validate-roles',
										meta: {
											window: {
												name: 'validate-roles',
												max_width: 600
											}
										}
									},
									{
										path: 'add-player',
										name: 'add-player'
									},
									{
										path: 'edit-player/:player_id',
										name: 'edit-player',
										meta: {
											window: {
												name: 'edit-player',
												max_width: 400
											}
										}
									}
								]
							},
							{
								path: 'game-access',
								name: 'room-game-access',
								children: [
									{
										path: 'decisions',
										name: 'room-game-access-decisions'
									},
									{
										path: 'news',
										name: 'room-game-access-news'
									},
									{
										path: 'chirps',
										name: 'room-game-access-chirps'
									},
									{
										path: 'private-messaging',
										name: 'room-game-access-private-messaging'
									},
									{
										path: 'group-messaging',
										name: 'room-game-access-group-messaging'
									},
									{
										path: 'student-assessments',
										name: 'room-game-access-student-assessments'
									}
								]
							},
							{
								path: 'stats',
								name: 'room-stats'
							},
							{
								path: 'start',
								name: 'start-game',
								meta: {
									window: {
										name: 'start-game',
										max_width: 500
									}
								}
							},
							{
								path: 'next',
								name: 'next-game',
								meta: {
									window: {
										name: 'next-game',
										max_width: 500
									}
								}
							},
							{
								path: 'stop',
								name: 'stop-game',
								meta: {
									window: {
										name: 'stop-game',
										max_width: 500
									}
								}
							},
							{
								path: 'launch-videos',
								name: 'launch-videos',
								meta: {
									window: {
										name: 'launch-videos',
										max_width: 600
									}
								}
							},
						]
					}
				]
			},
			{
				path: 'scenarios',
				name: 'scenarios',
				component: () => import('@/views/master/view-scenarios.vue')
			},
			// {
			// 	path: 'contacts',
			// 	name: 'contacts',
			// 	component: () => import('@/views/master/view-contacts.vue')
			// },
			{
				path: 'logout',
				name: 'logout',
				meta: {
					window: {
						name: 'logout',
						max_width: 500
					}
				}
			}
		]
	},
	{
		path: '/login/:room_code?/:student_number?',
		name: 'login',
		component: () => import('@/views/view-login.vue')
	},
	{
		path: '/teacher-signup',
		name: 'teacher-signup',
		component: () => import('@/views/view-teacher-signup.vue')
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import('@/views/view-reset-password.vue')
	},
	{
		path: '/scenarios',
		name: 'scenarios-list',
		component: () => import('@/views/view-scenarios.vue')
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
})


router.beforeEach(async (to, from, next) => {

	// Redirect to login if not logged
	if (!store.getters.logged_user) {
		if (to.name !== 'login' && to.name !== 'teacher-signup' && to.name !== 'reset-password' && to.name !== 'scenarios-list' && to.name !== 'login-master' && to.name !== 'quick-game') {
			next({ path: '/login' })
			return
		}
	}

	else {
		const user = store.getters.logged_user

		if (to.name === 'default' || to.name === 'login') {
			if (user.type === 'game-master') next({ path: '/master' })
			if (user.type === 'player') next({ path: '/game' })
			return
		}

		if (to.path.substr(0, 7) === '/master' && user.type !== 'game-master') {
			if (user.type === 'player') next({ path: '/game' })
			else next({ path: '/' })
			return
		}

		if (to.path.substr(0, 5) === '/game' && user.type !== 'player') {
			if (user.type === 'game-master') next({ path: '/master' })
			else next({ path: '/' })
			return
		}


		// GET CURRENT ROOM
		if (to.path.substr(0, 7) === '/master') {
			if (to.params.room_id) {
				if (!store.getters.game.room || store.getters.game.room.id !== to.params.room_id) {
					let room = await Apollo.query('GET_ROOM', { id: to.params.room_id })
					if (room) {
						await Medias.init({ scenario: room.scenario_id })

						const scenario = store.getters.master.scenarios.find((s) => parseInt(s.id) === parseInt(room.scenario_id))
						store.commit('EDIT_GAME_STATE', { scenario })

						await store.dispatch('load_players', { room })
						await store.dispatch('load_all_questions', { room })
						await store.dispatch('load_master_polling_results', { room }),
						await store.dispatch('load_outcomes', { room }),
						store.dispatch('load_scenario_config', { room }),
						
						store.commit('EDIT_GAME_STATE', { room })
						Pulling.start()
					}
				}
			} else  {
				store.commit('EDIT_GAME_STATE', { room: false })
				store.commit('EDIT_MASTER_STATE', { questions: [], private_messages: [] })
				store.commit('EDIT_OUTCOME_STATE', { questions: [], dones: [] })
			}
		}

	}

	next()
})

export default router
