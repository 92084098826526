<template>
	<div class="chirp-form-new">
		
		<div class="form" :class="{ 'has-medias': chirp.current.selected_media.length > 0 }">
			<div v-show="!text" class="placeholder" :class="{ focused: focused == 'text' }">Share your chirp</div>
			<div class="textarea" v-scrollbar>
				<div ref="textarea_content" contenteditable @input="set_text" @focus="set_focus('text')" @blur="set_focus('')"></div>
			</div>
			<div v-if="chirp.current.selected_media" class="medias">
				<div v-for="media in chirp.current.selected_media" class="media" :key="media.id">
					<div class="img" v-bg="media"></div>
					<div class="remove" v-hammer:tap="() => remove_media(media)"></div>
				</div>
			</div>
		</div>

		<div class="buttons">
			<div class="button attach active" v-hammer:tap="() => show_media_selection()"><!--<ui-icon name="image" :size="32" />--></div>
			<div class="infos">
				<span class="nb-characters" :class="{ error: nb_chars_left < 0 }">{{ nb_chars_left }}<span class="char">char</span></span>
			</div>
			<div class="button send" :class="{ active: sendable }" v-hammer:tap="() => send()"><ui-icon name="send-white" :size="32" /></div>
		</div>

		<div v-show="loading" class="loading"><ui-icon name="button-loader" size="48" /></div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'chirp-form-new',
	data() {
		return {
			loading: false,
			title: '',
			text: '',
			focused: ''
		}
	},
	computed: {
		...mapGetters([ 'chirp' ]),
		sendable() {
			return this.text != '' && this.nb_chars_left >= 0
		},
		nb_chars_left() {
			return 280 - this.text.length
		}
	},
	watch: {
		text(newValue) {
			localStorage.setItem('ithrive-text-chirp', newValue)
		}
	},
	methods: {
		set_text(e) {
			this.text = e.target.innerText
			if (this.text == '\n') this.text = ''
		},
		cancel() {
			this.$emit('cancel')
		},
		show_media_selection() {
			// let media_selection_multiple = (this.type == 'chirp')
			// this.$store.dispatch('set_chirp_current', { media_selection_visible: true })
		},
		set_focus(el) {
			this.focused = el
		},
		remove_media(media) {
			let medias = this.chirp.current.selected_media.filter((m) => { return m.id != media.id })
			this.$store.dispatch('set_chirp_current', { selected_media: medias })
		},
		async send() {
			if (!this.loading) {
				this.loading = true
				let message_id = await this.$store.dispatch('send_message', {
					title: this.title,
					message: this.text,
					channel: '',
					tags: [ 'all' ],
					message_type: 'Chirp',
					medias: this.chirp.current.selected_media.map((m) => { return m.url })
				})
				if (message_id && message_id > 0) {
					this.title = ''
					this.text = ''
					this.$refs.textarea_content.innerHTML = ''
					this.$store.dispatch('set_chirp_current', { media_selection_visible: false, selected_media: [] })
					this.$store.dispatch('add_notification', {
						status: 'success', 
						message: "Sent!"
					})
				}
				this.loading = false
			}
		}
	},
	mounted() {
		let text = localStorage.getItem('ithrive-text-chirp')
		if (text) {
			this.text = text
			this.$refs.textarea_content.innerHTML = text
		}
		this.$refs.textarea_content.focus()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.chirp-form-new
	height 100%
	&.form-new-story
		.form
			top 88px
	.form-title
		position absolute
		left 32px
		top 16px
		right 32px
		height 64px
		// padding 16px
		background-color darken(blue_light, 10%)
		border-radius 12px
		.placeholder
			top 20px
		input
			display block
			width 100%
			height 100%
			padding 0 16px
			border none
			background transparent
			font inherit
			color inherit
			font-size 1.6rem
			line-height 24px
	.form
		position absolute
		left 32px
		top 16px
		right 32px
		bottom (16px + 48px)
		padding 16px
		background-color var(--color-chirp-form-bg)
		color var(--color-chirp-form-text)
		border-radius 12px
		font-size 1.6rem
		line-height 24px
		box-shadow 0 0 4px alpha(#000, 15%)
		&.has-medias
			.textarea
				height calc(100% - 104px)
		.textarea
			display block
			height 100%
			word-wrap break-word
			font-size 1.6rem
			> div
				min-height 100%
				outline none
		.medias
			height 104px
			padding 8px 0 0 0
			flex flex-start center
			display none
			overflow auto
			-webkit-overflow-scrolling touch
			.media
				display inline-block
				width 96px
				height 96px
				margin 0 16px 0 0
				flex-shrink 0
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
					border-radius 8px
				.remove
					position absolute
					right -8px
					top -8px
					width 32px
					height 32px
					background red url(../../../../assets/img/close.svg) center center no-repeat
					background-size 12px 12px
					border-radius 50%
					cursor pointer
	.placeholder
		position absolute
		top 16px
		left 16px
		right 16px
		padding 0 0 4px 0
		color gray_light
		white-space nowrap
		line-height 24px
		font-size 1.6rem
		&:before
			content ''
			position absolute
			bottom 0
			left 0
			width 75%
			border-bottom 1px solid gray_light
		&.focused
			opacity 0.5
			&:before
				display none
	.buttons
		position absolute
		left 32px
		bottom 16px
		right 32px
		height 40px
		flex space-between center 
		line-height @height
		.button
			width 40px
			height 40px
			border-radius 50%
			flex center center
			cursor pointer
			opacity 0.5
			cursor not-allowed
			&.active
				opacity 1
				cursor pointer
		.infos
			height 40px
			flex center center
			flex-direction column
			.nb-characters
				flex center center
				flex-direction column
				font-size 1.8rem
				line-height 20px
				width 36px
				text-align center
				color var(--color-chirp-form-nb-char-text)
				&.error
					color var(--color-chirp-form-nb-char-text-error)
					.char:before
						background-color var(--color-chirp-form-nb-char-text-error)
				.char
					font-size 1.2rem
					text-transform uppercase
					&:before
						content ''
						position absolute
						left 0
						right 0
						top 0
						height 1px
						background-color var(--color-chirp-form-nb-char-text)
	
	.loading
		absolute 0
		background var(--color-chirp-form-loading-overlay-bg)
		color var(--color-chirp-form-loading-overlay-text)
		flex center center
		opacity 0.75

</style>
