<template>
	<div class="chirp-profile">
		
		<div class="head">
			<div class="profile-infos">
				<div class="avatar">
					<div class="img" v-bg="{ name: logged_user.avatar, type: 'avatar' }"></div>
				</div>
				<div class="profile-infos-content">
					<div class="name">{{ logged_user.name }}</div>
					<div class="player-name">({{ logged_user.student_name }})</div>
					<div class="username">@{{ logged_user.chirp_username }}</div>
				</div>
			</div>
		</div>

		<div class="content">
			<div class="profile-menu">
				<div class="chirp-content-wrap" style="background: transparent;">
					<div class="buttons">
						<div class="button" :class="{ active: profile_action == 'your-chirps' }" v-hammer:tap="() => set_profile_action('your-chirps')">Your chirps</div>
						<div class="button" :class="{ active: profile_action == 'liked-chirps' }" v-hammer:tap="() => set_profile_action('liked-chirps')">Liked chirps</div>
					</div>
				</div>
			</div>

			<div v-if="profile_action == 'your-chirps'" class="profile-chirps" v-scrollbar>
				<div>
					<div class="chirp-content-wrap">
						<chirp-item v-for="chirp_item in owned_chirps" :key="chirp_item.id" :chirp_item="chirp_item" />
					</div>
				</div>
			</div>

			<div v-if="profile_action == 'liked-chirps'" class="profile-chirps" v-scrollbar>
				<div>
					<div class="chirp-content-wrap">
						<chirp-item v-for="chirp_item in liked_chirps" :key="chirp_item.id" :chirp_item="chirp_item" />
					</div>
				</div>
			</div>
			

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChirpItem from '@/components/game/chirp/elements/chirp-item'

export default {
	name: 'chirp-profile',
	data() {
		return {
			profile_action: 'your-chirps'
		}
	},
	computed: {
		...mapGetters([ 'logged_user', 'chirp' ]),
		owned_chirps() {
			return this.chirp.feed.filter((c) => { return c.sender.id == this.logged_user.id })
		},
		liked_chirps() {
			return this.chirp.feed.filter((c) => { return c.like_by.includes(this.logged_user.id) })
		}
	},
	methods: {
		set_profile_action(action) {
			this.profile_action = action
		}
	},
	components: { ChirpItem }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.chirp-profile
	height 100%
	.head
		background-color blue_dark
		height 160px
		flex center center
		.profile-infos
			flex center center
			padding 16px
			height calc(100% - 32px)
			width calc(100% - 32px)
			background-color blue
			border-radius 8px
			text-align center
			white-space nowrap
			.avatar
				height (64px + 12px)
				width (64px + 12px)
				margin 0 16px 0 0 
				background-color green_light
				border-radius 50%
				flex center center
				overflow hidden
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
			.profile-infos-content
				height 100%
				flex center flex-start
				flex-direction column
				text-align left
				.name
					text-transform uppercase
					font-weight 700
					font-size 1.8rem
					line-height 24px
				.username
					margin 4px 0 0 0
					font-size 1.4rem
					line-height 16px
	.content
		height 'calc(100% - %s)' % (160px)
		.profile-menu
			height (32px + 16px)
			padding 0 16px
			.buttons
				flex center center
				height 32px
				.button
					display inline-block
					height 100%
					flex center center
					width 50%
					font-weight 700
					font-size 1.6rem
					text-transform uppercase
					box-shadow 0 0 0 1px chirp
					cursor pointer
					&:first-child
						border-top-left-radius 16px
						border-bottom-left-radius 16px
					&:last-child
						border-top-right-radius 16px
						border-bottom-right-radius 16px
					&.active
						background-color chirp
						color dark
		.profile-chirps
			height 'calc(100% - %s)' % (48px)
			
</style>
