<template>
	<div v-show="new_messages" class="new-messages-notification" v-hammer:tap="() => toggle_chirp('messages')">
		<ui-icon class="icon" :class="{ shake: new_messages > 0 }" name="chirp/chirp-messages" :size="16" />
		<span class="name">{{ new_messages }} new message{{ new_messages > 1 ? 's' : '' }}</span>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters([ 'app', 'chirp', 'logged_user', 'current_question_conversation', 'progress' ]),
		new_messages() {
			return this.chirp.messages.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id !== this.logged_user.id }).length +
				this.new_decision_conversation
		},
		new_decision_conversation() {
			if (this.current_question_conversation) {
				let answer = this.current_question_conversation.answer.find((a) => { return this.progress.selected_answers.includes(parseInt(a.id)) })
				if (!answer) return 1
			}
			return 0
		},
	},
	methods: {
		toggle_chirp(route) {
			if (this.app.device === 'mobile' || this.app.device === 'tablet-vertical' || this.app.device === 'tablet-horizontal') {
				this.$router.push({ path: '/game/chirp/' + route })
				this.$store.dispatch('set_chirp_route', { name: route })
			} else {
				if (this.chirp.route && this.chirp.route.name === route) {
					this.$store.commit('EDIT_CHIRP_STATE', { route: null })
				} else {
					this.$store.dispatch('set_chirp_route', { name: route })
				}
			}
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.new-messages-notification
	position absolute
	left 50%
	top calc(50% - 340px)
	transform translateX(-50%)
	background-color #FF3030
	color #fff
	border-radius 40px
	height 32px
	padding 0 16px
	line-height @height
	flex flex-start center
	gap 8px
	font-weight 600
	font-size 1.2rem
	text-transform uppercase
	cursor pointer
	box-shadow 0 0 8px 2px darken(#FF3030, 50%)
	animation pop 0.75s easeOutQuart infinite alternate
	@keyframes pop
		100%
			transform translate(-50%, -4px)
			// transform translateX(-50%) scale(1.05)
	&:hover
		background-color darken(#FF3030, 25%)
	

</style>