import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import Sounds from '@/js/sounds'
import { Medias } from '@/js/utils/medias'
import { cloneDeep } from '@/js/vendor/lodash.custom.min.js'
import { includesAtLeastOneItemInArray } from '@/js/utils/utils'


const $chirp_routes = [
	{ name: 'feed', meta: { menu: 'feed' } },
	{ name: 'messages', meta: { menu: 'messages' } },
	{ name: 'players', meta: { menu: 'messages', backButton: true } },
	{ name: 'conversation', meta: { menu: 'messages', backButton: true } },
	{ name: 'conversation-question', meta: { menu: 'messages', backButton: true } },
	{ name: 'post', meta: { menu: 'post' } },
	{ name: 'profile', meta: { menu: 'profile' } },
]


// === STATE =====================================================================================
const state = {
	route: null,
	visible: false,
	feed: [],
	messages: [],
	stories: [],
	news_papers: [
		{ id: 1, slug: 'the-buzz' },
		{ id: 2, slug: 'capitol-news' },
		{ id: 3, slug: 'world-news-network' },
		{ id: 4, slug: 'usa-news' },
		{ id: 5, slug: 'tri-force-news' },
		{ id: 6, slug: 'star-news' },
		{ id: 7, slug: 'nola-post' },
		{ id: 8, slug: 'the-white-house' }
	],
	current: {
		player: null,
		send_file: null,
		selected_media: [],
		media_selection_visible: false,
		media_viewer: null
	}
	// active: {
	// 	notification_chirp: false,
	// 	conversation: false,
	// 	conversation_new: false,
	// 	media_viewer: false,
	// 	media_selection: false,
	// 	media_selection_multiple: false,
	// 	selected_media: []
	// }
}








// === GETTERS =====================================================================================
const getters = {
	chirp(state) { return state }
}







// === ACTIONS =====================================================================================
const actions = {

	set_chirp({ commit }, data) {
		commit('EDIT_CHIRP_STATE', data)
	},

	set_chirp_route({ commit }, data) {
		if (data.name) {
			let destination_route = $chirp_routes.find((r) => { return r.name === data.name })
			if (destination_route) {
				let route = { ...cloneDeep(destination_route), params: (data.params || {}) }
				commit('EDIT_CHIRP_STATE', { route })
			}
		}
	},

	set_chirp_current({ commit }, data) {
		commit('EDIT_CHIRP_CURRENT', data)
	},



	async load_chirps({ commit, state, rootGetters }, data = {}) {
		let r = { status: 'error', message: '' }
		if (rootGetters.logged_user) {
			try {
				let chirps = []
				if (rootGetters.logged_user.type === 'game-master')
					chirps = cloneDeep( await Apollo.query('GET_MESSAGE_BY_ROOM', { room_id: rootGetters.game.room.id }) )
				else
					chirps = cloneDeep( await Apollo.query('GET_MESSAGE_BY_PLAYER', { player_id: rootGetters.logged_user.id }) )
				
				chirps.forEach(m => {
					// if (m.media) m.media = m.media.map((n) => { return Medias.get({ name: n })})
					if (m.sender && m.sender.id) m.sender_player = rootGetters.game.players.find((p) => parseInt(p.id) === parseInt(m.sender.id))
					if (m.recipient && m.recipient.id) m.recipient_player = rootGetters.game.players.find((p) => parseInt(p.id) === parseInt(m.recipient.id))
				})

				let feed = chirps.filter((m) => { return !m.recipient && !m.channel && !includesAtLeastOneItemInArray(m.tags, [ 'the-buzz', 'capitol-news', 'world-news-network', 'usa-news', 'tri-force-news', 'star-news', 'nola-post', 'the-white-house' ]) })
				let stories = chirps.filter((m) => { return !m.recipient && !m.channel && includesAtLeastOneItemInArray(m.tags, [ 'the-buzz', 'capitol-news', 'world-news-network', 'usa-news', 'tri-force-news', 'star-news', 'nola-post', 'the-white-house' ]) })
				let messages = chirps.filter((m) => { return m.channel || (m.recipient && (parseInt(m.recipient.id) === parseInt(rootGetters.logged_user.id) || parseInt(m.sender.id) === parseInt(rootGetters.logged_user.id))) })

				// if (messages.length > state.messages.length) {
				// 	Sounds.play('notification')
				// }

				commit('EDIT_CHIRP_STATE', { feed, messages, stories })

				if (rootGetters.logged_user.type === 'game-master') {
					let private_messages = chirps.filter((m) => m.recipient)
					commit('EDIT_MASTER_STATE', { private_messages })
				}

				r.status = 'success'
			}
			catch (error) {
				r.message = error.message
			}
		}
		
		return r
	},



	async update_chirps({ commit, state, rootGetters }, data = {}) {
		let r = { status: 'error', message: '' }
		
		if (rootGetters.logged_user) {
			try {
				let data = []
				
				if (rootGetters.logged_user.type === 'game-master')
					data = cloneDeep( await Apollo.query('GET_MESSAGE_BY_ROOM', { room_id: rootGetters.game.room.id }) )
				else
					data = cloneDeep( await Apollo.query('GET_MESSAGE_BY_PLAYER', { player_id: rootGetters.logged_user.id }))
				
				data.forEach((new_chirp) => {
					let arr = state.messages
					if (includesAtLeastOneItemInArray(new_chirp.tags, [ 'the-buzz', 'capitol-news', 'world-news-network', 'usa-news', 'tri-force-news', 'star-news', 'nola-post', 'the-white-house' ])) arr = state.stories
					else if (!new_chirp.recipient && !new_chirp.channel) arr = state.feed

					let old_chirp = arr.find((c) => { return parseInt(c.id) === parseInt(new_chirp.id) })
					if (old_chirp) {
						if (old_chirp.tags.includes('sending') || old_chirp.like !== new_chirp.like || old_chirp.share !== new_chirp.share) {
							commit('UPDATE_CHIRP', { old_chirp, new_chirp })
						}
					}
					else {
						// if (new_chirp.media) new_chirp.media = new_chirp.media.map((n) => { return Medias.get({ name: n })})
						commit('ADD_CHIRP', { array: arr, chirp: new_chirp })
					}
				})

				if (rootGetters.logged_user.type === 'game-master') {
					const private_messages = data.filter((m) => m.recipient)
					commit('EDIT_MASTER_STATE', { private_messages })
				}
				
			}
			catch (error) {
				r.message = error.message
			}
		}
		
		return r
	},


	// read_messages({ commit, state, rootGetters }, data) {
	// 	data.messages.forEach((message) => {
	// 		commit('EDIT_CHIRP', { chirp: message, read_by: [...message.read_by, rootGetters.logged_user.id] })
	// 		Apollo.mutate('READ_MESSAGE', { message_id: parseInt(message.id), player_id: rootGetters.logged_user.id })
	// 	})
	// },


	read_message({ commit, state, rootGetters }, data) {
		if (data.message.id) {
			let message = [ ...state.feed, ...state.messages, ...state.stories ].find((m) => parseInt(m.id) === parseInt(data.message.id))
			if (message) {
				// console.log({ chirp: data.message, read_by: [...data.message.read_by, rootGetters.logged_user.id] })
				commit('EDIT_CHIRP', { chirp: message, read_by: [...message.read_by, rootGetters.logged_user.id] })
				Apollo.mutate('READ_MESSAGE', { message_id: parseInt(message.id), player_id: rootGetters.logged_user.id })
			}
		}
	},


	async like_chirp({ commit, rootGetters }, data) {
		if (data.chirp) {
			try {
				commit('LIKE_CHIRP', { chirp: data.chirp, user: rootGetters.logged_user })
				let r = await Apollo.mutate('LIKE_MESSAGE', { message_id: parseInt(data.chirp.id), player_id: rootGetters.logged_user.id })
				return r
			}
			catch (error) {}
			return false
		}
	},


	async share_chirp({ commit, rootGetters }, data) {
		if (data.chirp) {
			try {
				commit('SHARE_CHIRP', { chirp: data.chirp, user: rootGetters.logged_user })
				let r = await Apollo.mutate('SHARE_MESSAGE', { message_id: parseInt(data.chirp.id), player_id: rootGetters.logged_user.id })
				return r
			}
			catch (error) {}
			return false
		}
	},


	async unlike_chirp({ commit, rootGetters }, data) {
		if (data.chirp) {
			try {
				commit('UNLIKE_CHIRP', { chirp: data.chirp, user: rootGetters.logged_user })
				let r = await Apollo.mutate('UNLIKE_MESSAGE', { message_id: parseInt(data.chirp.id), player_id: rootGetters.logged_user.id })
				return r
			}
			catch (error) {}
			return false
		}
	},


	async unshare_chirp({ commit, rootGetters }, data) {
		if (data.chirp) {
			try {
				commit('UNSHARE_CHIRP', { chirp: data.chirp, user: rootGetters.logged_user })
				let r = await Apollo.mutate('UNSHARE_MESSAGE', { message_id: parseInt(data.chirp.id), player_id: rootGetters.logged_user.id })
				return r
			}
			catch (error) {}
			return false
		}
	},


	async send_message({ state, commit, rootGetters }, data) {
		if (data.message) {
			try {
				let values = {
					room_id: rootGetters.game.room.id,
					sender_id: rootGetters.logged_user.id,
					recipient_id: null,
					tags: [],
					channel: data.channel,
					title: data.title,
					message: data.message,
					message_type: data.message_type,
					media: [],
				}
				if (data.tags) values.tags = data.tags
				if (data.medias) values.media = data.medias
				if (data.recipient) values.recipient_id = parseInt(data.recipient.id)

				let r = await Apollo.mutate('ADD_MESSAGE', values)
				if (r) {
					if (values.recipient_id || values.channel) {
						// console.log(values.medias)
						let medias = values.media ? values.media : []
						// data.medias.forEach((m) => { medias.push(Medias.get({ name: m })) })
						let message = {
							id: r,
							sender: rootGetters.logged_user,
							recipient: data.recipient,
							channel: values.channel,
							tags: values.tags,
							title: data.title,
							message: data.message,
							message_type: 'Chirp',
							media: medias,
							like: 0,
							like_by: [],
							share: 0,
							in_game_day_created: rootGetters.game.room.episode.episode_number,
							in_game_created: rootGetters.game.room.timer_total - rootGetters.game.room.timer,
							share_by: [],
							read_by: []
						}
						commit('ADD_CHIRP', { array: state.messages, chirp: message })
					}
					return r
				}
			}
			catch (error) {
				console.error(error)
			}
		}
		return false
	}

}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_CHIRP_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_CHIRP_CURRENT(state, data) {
		for (var i in data) {
			Vue.set(state.current, i, data[i])
		}
	},

	EDIT_CHIRP(state, data) {
		if (data.chirp) {
			for (var i in data) {
				if (i !== 'chirp') Vue.set(data.chirp, i, data[i])
			}
		}
	},

	UPDATE_CHIRP(state, data) {
		if (data.new_chirp && data.old_chirp) {
			let fields = [ 'like', 'share', 'tags' ]
			let keys = Object.keys(data.new_chirp)
			for (var i in keys) {
				let field = keys[i]
				if (fields.includes(field)) {
					if (data.new_chirp[field] !== data.old_chirp[field]) {
						Vue.set(data.old_chirp, field, data.new_chirp[field])
					}
				}
			}
		}
	},


	ADD_CHIRP(state, data) {
		if (data.array && data.chirp) {
			data.array.unshift(data.chirp)
		}
	},


	LIKE_CHIRP(state, data) {
		if (data.chirp) {
			data.chirp.like += 1
			data.chirp.like_by.push(data.user.id)
		}
	},


	SHARE_CHIRP(state, data) {
		if (data.chirp) {
			data.chirp.share += 1
			data.chirp.share_by.push(data.user.id)
		}
	},


	UNLIKE_CHIRP(state, data) {
		if (data.chirp) {
			data.chirp.like -= 1
			let index = data.chirp.like_by.findIndex((c) => { return parseInt(c) && parseInt(c) === parseInt(data.user.id) })
			if (index > -1) data.chirp.like_by.splice(index, 1)
		}
	},


	UNSHARE_CHIRP(state, data) {
		if (data.chirp) {
			data.chirp.share -= 1
			let index = data.chirp.share_by.findIndex((c) => { return parseInt(c) && parseInt(c) === parseInt(data.user.id) })
			if (index > -1) data.chirp.share_by.splice(index, 1)
		}
	},

}

export default { state, getters, actions, mutations }
