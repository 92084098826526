<template>
	<div class="ui-link" :class="{ 'active': is_active, 'exact-active': is_exact_active, disabled }">
		<slot />
	</div>
</template>

<script>
import Hammer from 'hammerjs'

export default {
	name: 'ui-link',
	data() {
		return {
			
		}
	},
	props: {
		to: { default: '' },
		disabled: { default: false }
	},
	computed: {
		is_active() {
			if (this.is_exact_active) return true
			if (this.$route.matched.length) {
				let r = this.$route.matched.find((r) => {
					return new RegExp(r.regex.source).test(this.to)
				})
				if (r) return true
			}
			return false
		},
		is_exact_active() {
			let r = this.$route.path
			if (r.length > 1 && r.substr(-1, 1) == '/') r = r.substr(0, r.length - 1)
			return (r == this.to)
		}
	},
	mounted() {
		let mc = new Hammer.Manager(this.$el)
		mc.add( new Hammer.Tap() )
		mc.on("tap", (e) => {
			e.srcEvent.stopImmediatePropagation()
			if (!this.disabled) {
				if (!this.is_exact_active) {
					if (this.to) this.$router.push({ path: this.to })
				}
			}
		})
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'



</style>
