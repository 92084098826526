import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import { VueHammer } from 'vue2-hammer'
import Scrollbar from '@/plugins/scrollbar'
import BackgroundImage from '@/plugins/background-image'
import jquery from 'jquery'
import Fancybox from '@/plugins/fancybox'
import UiIcon from '@/components/ui/ui-icon'
import UiLink from '@/components/ui/ui-link'
import '@/js/utils/polyfills'
import InlineSvg from 'vue-inline-svg'

window.jQuery = jquery
window.$ = jquery

Vue.config.productionTip = false


Vue.use(VueHammer)
Vue.use(Scrollbar)
Vue.use(BackgroundImage)
Vue.use(Fancybox)
Vue.component('inline-svg', InlineSvg)
Vue.component('ui-icon', UiIcon)
Vue.component('ui-link', UiLink)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')