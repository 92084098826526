<template>
	<div class="chirp-conversation">

		<div v-if="sender" v-show="!app.is_ipad_theme || !current_dm_question" class="recipient">
			<div class="recipent-avatar"><div class="img" v-bg="{ name: sender.avatar, type: 'avatar' }"></div></div>
			<div class="recipent-content">
				<p v-if="sender.role && sender.role.name" class="role">{{ sender.role.name }}</p>
				<p v-if="sender.name" class="name">
					{{ sender.name }}
					<span v-if="sender.student_name">({{ sender.student_name }})</span>
				</p>
			</div>
		</div>

		<div class="conversation" ref="conversation" v-scrollbar>
			<div>
				<div v-if="messages.length" class="messages">
					<chirp-message v-for="message in messages" :key="message.key" :message="message" />
					<div v-if="answering_visible" class="answering">
						<div class="dots">
							<span class="dot"></span>
							<span class="dot"></span>
							<span class="dot"></span>
						</div>
					</div>
				</div>
				<div v-else class="no-messages">
					<ui-icon name="message-square" :size="64" />
					<div>No messages</div>
				</div>
			</div>
		</div>
		
		<div v-if="current_dm_question" class="form" ref="form" :class="{ loading }">
			<p class="title">
				<span>Choose your answer:</span>
				<span class="timer" title="Time left"><ui-icon name="hourglass" :size="16" /> {{ current_dm_question_timer }}</span>
			</p>
			<div class="answers">
				<div v-for="answer in current_dm_question.answer" :key="answer.id" class="answer" :class="{ loading: loading && answer.id === loading.id }" @click="send_answer(answer)">
					<div class="text" v-html="answer.answer"></div>
					<!-- <div v-if="loading && answer.id === loading.id" class="loader">
						<ui-icon name="button-loader" :size="48" />
					</div> -->
				</div>
			</div>
			<div v-if="loading" class="loading-overlay"><ui-icon name="button-loader" :size="48" /></div>
		</div>
		<div v-else class="form-textarea" ref="form_textarea" :class="{ 'has-media': chirp.current.selected_media.length > 0, loading }">
			<div v-if="sender" class="image-button" v-hammer:tap="() => show_media_selection()"><ui-icon name="image" :size="32" /></div>
			<div class="input" :class="{ 'full-width': !sender }">
				<div v-if="app.is_ipad_theme" class="textarea">Your message here...</div>
				<textarea v-else placeholder="Your message here..." v-model="text" @keyup="update_height" @keydown.enter.prevent="send_message" rows="1" ref="textarea" class="textarea" />
				<div class="submit-button" v-hammer:tap="() => send()"><ui-icon name="send" :size="20" /></div>
			</div>
			<div v-if="loading" class="loading-overlay"><ui-icon name="button-loader" :size="48" /></div>
		</div>

		<div v-if="loading" class="prevent"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChirpMessage from '@/components/game/chirp/elements/chirp-message'
import Feature from 'feature.js'
import { wait } from '@/js/utils/utils'

const generateMessage = ({ id, sender, message, time }) => {
	return { id, sender, message, time, tags: [], read_by: [], media: [] }
}

export default {
	name: 'chirp-conversation-question',
	data() {
		return {
			// messages: [],
			// answered: false,
			loading: null,
			answering_id: null,
			answering_visible: false,
			text: ''
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'chirp', 'logged_user', 'progress', 'questions', 'current_question_conversation' ]),
		avatar() {
			return this.sender.avatar
		},
		sender() {
			return this.chirp.route.params.player || null
		},

		current_dm_question() {
			if (
				this.current_question_conversation &&
				parseInt(this.current_question_conversation?.sender_id_id) === parseInt(this.sender?.role?.id) &&
				!this.current_question_conversation.answer.find(a => this.progress.selected_answers.includes(parseInt(a.id)))
			)
				return this.current_question_conversation
			return null
		},

		past_dm_questions() {
			if (this.questions.length > 0) {
				const t = this.game.room.timer_total - this.game.room.timer
				return this.questions.filter((q) => {
					return q.timer < t && q.direct_message && q.sender_id_id && parseInt(q?.sender_id_id) === parseInt(this.sender?.role?.id)
				})
			}
			return null
		},

		current_dm_question_timer() {
			if (this.current_dm_question) {
				const t = this.game.room.timer_total - this.game.room.timer
				return this.current_dm_question.timeout - (t - this.current_dm_question.timer)
			}
			return null
		},
		
		// Fusion des messages privées et DM decisions passées
		messages() {
			const messages = []

			if (this.sender?.id) {
				let key = 1
				// const t = this.game.room.timer_total - this.game.room.timer

				// DM questions
				this.past_dm_questions.forEach(question => {
					messages.push(generateMessage({ key: key++, sender: this.sender, message: question.question, time: question.timer }))
					if (question.description) messages.push(generateMessage({ key: key++, sender: this.sender, message: question.description, time: question.timer + 0.1 }))
					const answer = question.answer.find(a => this.progress.selected_answers.includes(parseInt(a.id)) )
					if (answer) {
						messages.push(generateMessage({ key: key++, sender: this.logged_user, message: answer.answer, time: question.timer + 0.2 }))
						if (answer.id !== this.answering_id) {
							messages.push(generateMessage({ key: key++, sender: this.sender, message: answer.feedback, time: question.timer + 0.3 }))
						}
					}
				})

				// Private messages
				this.chirp.messages.forEach((m) => {
					if (m.recipient && (m.recipient.id === this.sender.id || m.sender.id === this.sender.id) && m.message.trim()) {
						messages.push(generateMessage({ key: key++, id: m.id, sender: m.sender, message: m.message, time: m.in_game_created }))
					}
				})
			}

			return messages.sort((a, b) => a.time > b.time ? 1 : -1)
		}
	},
	watch: {
		'messages.length'() {
			this.$nextTick(() => {
				this.scroll_to_bottom()
			})
		},
		text() {
			this.update_height()
		},
	},
	methods: {
		remove_media(media) {
			let medias = this.chirp.current.selected_media.filter((m) => { return m.id != media.id })
			this.$store.dispatch('set_chirp_active', { selected_media: medias })
		},
		show_media_selection() {
			this.$store.dispatch('set_chirp_current', { media_selection_visible: true, selected_media: [] })
		},
		update_height() {
			let textarea = this.$refs.textarea
			setTimeout(() => {
				if (textarea) {
					textarea.style.cssText = 'height:auto;'
					textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px'
				}
				this.$nextTick(() => {
					this.scroll_to_bottom()
				})
			}, 10)

		},
		scroll_to_bottom() {
			let el = this.$refs.conversation
			if (el) {
				if (!Feature.touch) el = el.firstChild
				el.scrollTop = el.scrollHeight
			}
		},
		async send_answer(answer) {
			if (answer && !this.loading) {
				this.loading = answer
				this.answering_id = answer.id
				this.$store.commit('EDIT_PROGRESS_STATE', { selected_answers: [ ...this.progress.selected_answers, parseInt(answer.id) ] })
				
				// SEND ANSWER
				await this.$store.dispatch('send_answer', { question: this.question, answer })

				await wait(1000)
				this.answering_visible = true
				await wait(2000)
				this.answering_id = null
				this.answering_visible = false
				this.loading = null
			}
		},
		async send_message() {
			if (this.text && !this.loading) {
				this.loading = true
				let values = {
					title: '',
					channel: '',
					message: this.text,
					tags: [],
					message_type: 'Chirp',
					medias: this.chirp.current.selected_media.map((m) => { return m.url })
				}
				if (this.sender) values.recipient = this.sender
				let message_id = await this.$store.dispatch('send_message', values)
				if (message_id && message_id > 0) {
					this.title = ''
					this.text = ''
					// this.$refs.textarea_content.innerHTML = ''
					// this.$store.dispatch('set_chirp_active', { selected_media: [] })
					this.$store.dispatch('add_notification', {
						status: 'success',
						message: "Sent!"
					})
				}
				this.loading = false
			}
		}
	},
	async mounted() {
		this.scroll_to_bottom()
	},
	components: { ChirpMessage }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'

#app.ipad-theme
	.chirp-conversation
		.recipient
			height 40px
			.recipent-avatar
				height 24px
				width 24px
			.recipent-content
				flex flex-start flex-start
				gap 8px
		.form-textarea
			padding-bottom 16px
			pointer-events none
		.form
			padding-top 8px
			> .title
				margin-bottom 8px
			.answers .answer .text
				padding 8px 16px

.chirp-conversation
	height 100%
	background-color var(--color-chirp-bg)
	flex flex-start flex-start column
	// padding 0 32px
	.recipient
		flex flex-start center
		width 100%
		flex-shrink 0
		padding 12px 16px
		height 64px
		line-height 20px
		white-space nowrap
		background-color var(--color-chirp-conversation-recipient-bg)
		color var(--color-chirp-conversation-recipient-text)
		.recipent-avatar
			width 48px
			height 48px
			margin 0 12px 0 0
			background-color var(--color-chirp-user-avatar-bg)
			border-radius 50%
			.img
				absolute 0
				background-size cover
				border-radius 50%
				text-align center
				flex center center
				font-weight 900
				font-size 1.6rem
		.recipent-content
			.role
				text-transform uppercase
			.name
				opacity 0.5
	.conversation
		height 100%
		flex-shrink 1
		width 100%
		.messages
			padding 8px 16px 8px 8px
			.answering
				width 100%
				padding 16px 0 0 8px
				.dots
					height 40px
					padding 8px 16px
					padding-top 12px
					background-color var(--color-chirp-conversation-recipient-bg)
					flex flex-start flex-start
					display inline-flex
					border-radius 8px
					gap 8px
					.dot
						width 12px
						height 12px
						background-color var(--color-chirp-conversation-recipient-text)
						border-radius 50%
						animation 0.4s dot easeInQuart infinite alternate
						opacity 0.75
						&:nth-child(2)
							animation-delay 0.1s
						&:nth-child(3)
							animation-delay 0.2s
					@keyframes dot
						100%
							transform translateY(4px)
	.no-messages
		width 100%
		height 100%
		flex center center
		flex-shrink 1
		flex-direction column
		opacity 0.5
		text-transform uppercase
		font-weight 700
		font-size 1.8rem
		min-height 300px
		color var(--color-chirp-conversation-no-messages-text)
		> .ui-icon
			margin-bottom 8px
			&:after
				content ''
				position absolute
				left -50%
				top 0
				background-color var(--color-chirp-conversation-no-messages-text)
				height 5px
				width 150%
				box-shadow 0 -4px 0 0 var(--color-chirp-bg), 0 4px 0 0 var(--color-chirp-bg)
				transform rotate(45deg) translate(30%, 200%)
	.form
		width 100%
		flex-shrink 0
		min-height 64px
		padding 16px
		background-color var(--color-chirp-conversation-form-bg)
		// flex flex-start center
		
		&:before
			content ''
			position absolute
			left 16px
			right 16px
			top 0
			height 1px
			background-color var(--color-chirp-conversation-form-border)

		> .title
			width 100%
			margin 0 0 16px 0
			text-transform uppercase
			text-align center
			display flex
			justify-content space-between
			align-items center
			// background-color red
			height 24px
			font-size 1.6rem
			> .timer
				display flex
				align-items center
				gap 4px

		.answers
			width 100%
			padding 0 8px 0 0
			.answer
				width 100%
				margin 0 0 8px 0
				cursor pointer
				transition transform 0.15s easeOutQuart
				&:last-child
					margin-bottom 0
				&:not(.loading):hover
					transform translate(2px, -2px)
					&:before
						transform translate(-2px, 2px)
						opacity 1
					&:after
						border-color transparent transparent transparent var(--color-chirp-conversation-question-answer-bg-hover)
					.text
						background-color var(--color-chirp-conversation-question-answer-bg-hover)
						color var(--color-chirp-conversation-question-answer-text-hover)
				&:not(.loading):active
					transform translate(0, 0)
					&:before
						transform translate(0, 0)
						opacity 0
				&:before
					content ''
					absolute 0
					background-color alpha(#000, 75%)
					border-radius 32px
					transition 0.15s easeOutQuart
					opacity 0
				&:after
					content ''
					position absolute
					right -8px
					bottom -2px
					width 0
					height 0
					border-style solid
					border-width 8px 0 8px 24px
					border-color transparent transparent transparent var(--color-chirp-conversation-question-answer-bg)
					transform rotate(35deg)
				
				&.loading
					cursor wait
					.text
						background-color var(--color-chirp-conversation-question-answer-bg-loading)
						color var(--color-chirp-conversation-question-answer-text-loading)
					&:after
						border-color transparent transparent transparent var(--color-chirp-conversation-question-answer-bg-loading)
				.loader
					absolute 0
					z-index 1
					flex center center
					border-radius 32px
					color #fff

				.text
					background-color var(--color-chirp-conversation-question-answer-bg)
					color var(--color-chirp-conversation-question-answer-text)
					padding 16px 32px
					font-size 1.6rem
					border-radius 32px

		.loading-overlay
			absolute 0
			background var(--color-chirp-conversation-form-bg)
			flex center center
			opacity 0.9
			color var(--color-chirp-conversation-form-loading-overlay-text)

	.form-textarea
		// position absolute
		// bottom 0
		// left 0
		// right 0
		flex-shrink 1
		width 100%
		min-height 64px
		padding 16px
		padding-bottom 32px
		background-color var(--color-chirp-conversation-form-bg)
		flex flex-start center
		&.loading
			.loading-overlay
				absolute 0
				// bottom 32px
				background var(--color-chirp-conversation-form-bg)
				flex center center
				opacity 0.9
				color var(--color-chirp-conversation-form-loading-overlay-text)
		&:before
			content ''
			position absolute
			left 16px
			right 16px
			top 0
			height 1px
			background-color var(--color-chirp-conversation-form-border)
		.image-button
			width 32px
			height 32px
			margin 0 8px 0 0
			cursor pointer
			color var(--color-chirp-conversation-form-attachment-button)
		.input
			flex center center
			min-height 32px
			width calc(100% - 40px)
			background-color var(--color-chirp-conversation-form-input-bg)
			border-radius 24px
			color var(--color-chirp-conversation-form-input-text)
			&.full-width
				width 100%
			.textarea
				width 100%
				padding 10px 48px 8px 16px
				min-height 32px
				border none
				font inherit
				color inherit
				resize none
				line-height 16px
				overflow auto
				font-family 'Josefin Sans'
				font-size 16px
				background none
				outline none
				overflow hidden
				&::-webkit-input-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				&::-moz-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				&:-ms-input-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				&:-moz-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				
			.submit-button
				position absolute
				right 0
				bottom 0
				width 32px
				height 32px
				flex flex-start center
				cursor pointer
		.medias
			height (96px + 16px)
			padding 8px
			flex flex-start center
			overflow auto
			-webkit-overflow-scrolling touch
			.media
				display inline-block
				width 96px
				height 96px
				margin 0 16px 0 0
				flex-shrink 0
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
					border-radius 16px
				.remove
					position absolute
					right -8px
					top -8px
					width 32px
					height 32px
					background var(--color-error) url(../../../../assets/img/close.svg) center center no-repeat
					background-size 12px 12px
					border-radius 50%
					cursor pointer



	.prevent
		absolute 0
		cursor wait
		
</style>
