import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { sortArrayWithOrdersOnProperty } from '@/js/utils/utils'
// import { Medias } from '@/js/utils/medias'
// import Pulling from '@/js/pulling'



// === STATE =====================================================================================
const state = {
	room: false,
	players: [],
	roles: [],
	info_boxes: [],
	playing_video: null,
	scenario_config: null
	// time: {
	// 	remaining: 0,
	// 	total: 0
	// }
}








// === GETTERS =====================================================================================
const getters = {
	game(state) { return state }
}







// === ACTIONS =====================================================================================
const actions = {

	async load_roles({ commit }, data) {
		let all_roles = []
		if (data && data.scenario)
			all_roles = await Apollo.query('GET_ROLE_BY_SCENARIO', { scenario_id: parseInt(data.scenario.id) })
		else
			all_roles = await Apollo.query('ALL_ROLES')

		all_roles.sort((a, b) => {
			if (a.name > b.name) return 1
			return -1
		})
		let roles = sortArrayWithOrdersOnProperty(all_roles, [ 'Government', 'Law Enforcement', 'Media', 'Citizens' ], 'category')

		commit('EDIT_GAME_STATE', { roles })
	},
	

	async load_players({ commit }, data) {
		let r = { status: 'error', message: '' }

		try {
			let players = await Apollo.query('GET_PLAYER_BY_ROOM', { room_id: data.room.id })
			commit('EDIT_GAME_STATE', { players })
			r.status = 'success'
		}
		catch (error) {
			r.message = error.message
		}
		
		return r
	},
	

	async load_scenario_config({ state, commit }) {
		let r = { status: 'error', message: '' }

		try {
			let scenario_config = await Apollo.query('GET_CONFIG', { scenario_id: parseInt(state.scenario.id) })
			if (scenario_config) commit('EDIT_GAME_STATE', { scenario_config })
		}
		catch (error) {
			r.message = error.message
		}
		
		return r
	},
	

	async load_info_boxes({ dispatch, commit, rootGetters }, data) {
		let r = { status: 'error', message: '' }

		try {
			const info_boxes_data = await Apollo.query('GET_INFO_BOX', { scenario_id: parseInt(data.scenario.id) })
			const info_boxes = info_boxes_data
				.filter((i) => { return i.day === rootGetters.game.room.episode.episode_number && (i.all_role || i.roles.includes(parseInt(rootGetters.logged_user.role.id))) })
				.map((info_box, counter) => ({ ...info_box, counter: counter + 1 }))
			commit('EDIT_GAME_STATE', { info_boxes })
			r.status = 'success'
		}
		catch (error) {
			r.message = error.message
		}
		
		return r
	},


	async update_player_role({ commit }, data) {
		if (data.player && data.player.id) {
			const user = await Apollo.query('GET_PLAYER', { id: data.player.id })
			if (user) {
				if (user.role.id != data.player.role.id) {
					commit('EDIT_PLAYER', { player: data.player, role: user.role })
				}
			}
		}
	},



	edit_room({ commit }, data) {
		if (data.room) commit('EDIT_ROOM', data)
	},



	async get_player_review({ rootGetters }, data) {
		if (data.player_id) {
			return await Apollo.query('GET_PLAYER_REVIEW', {
				room_id: parseInt(rootGetters.game.room.id),
				episode_number: rootGetters.game.room.episode.episode_number,
				player_id: data.player_id,
			})
		}
	},

	async set_player_review({ rootGetters }, data) {
		if (data.player_id) {
			return await Apollo.mutate('SET_PLAYER_REVIEW', {
				room_id: parseInt(rootGetters.game.room.id),
				episode_number: rootGetters.game.room.episode.episode_number,
				player_id: data.player_id,
				counter: data.counter,
				comment: data.comment || '',
				reviews: data.reviews || '',
			})
		}
	},


}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_GAME_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_PLAYER(state, data) {
		for (var i in data) {
			if (i != 'player') Vue.set(data.player, i, data[i])
		}
	},

	EDIT_ROOM(state, data) {
		for (var i in data) {
			if (i != 'room') Vue.set(data.room, i, data[i])
		}
	},

	EDIT_GAME_INFO_BOX(state, data) {
		for (var i in data) {
			if (i != 'info_box') Vue.set(data.info_box, i, data[i])
		}
	},



}

export default { state, getters, actions, mutations }
