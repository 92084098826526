// import axios from 'axios'
// import Apollo from '@/apollo/apollo-client'
import data_images from '@/data/images.json'
import _ from '@/js/vendor/lodash.custom.min.js'

const folders = {
	avatar: "avatars/"
}

const Medias = {
	files: [],
	async init(config) {
		Medias.files = []
		
		const files = require.context('@/assets/medias/', true, /^.*\.jpg|png|svg$/).keys()
		for (let i in files) {
			let name = files[i].substr(2)
			const url = require('@/assets/medias/' + name)

			// let blob = await axios({
			// 	method: 'get',
			// 	url: file,
			// 	responseType: 'blob'
			// })	
			// let url = URL.createObjectURL(blob.data)

			let roles = []
			let role_categories = []
			let time = 0
			let in_game_day_created = 0

			if (config && config.scenario) {
				let m = data_images.find((m) => { return m.url === name })
				if (m && m.scenario === config.scenario) {
					if (m.roles) roles = _.cloneDeep(m.roles)
					if (m.role_categories) role_categories = _.cloneDeep(m.role_categories)
					if (m.time) time = _.cloneDeep(m.time)
					if (m.in_game_day_created) in_game_day_created = _.cloneDeep(m.in_game_day_created)
				}
			}

			Medias.files.push({
				id: parseInt(i),
				name,
				roles,
				role_categories,
				in_game_day_created,
				url,
				time
			})

		}
	},
	get(data = {}) {
		let path = ''
		if (data.type) path = folders[data.type]
		return Medias.files.find((f) => { return f.name === path + data.name })
	}
}

export { Medias }