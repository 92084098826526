import ClockWorker from 'worker-loader!@/web-workers/steps.worker'
import store from '@/store/store'
import Apollo from '@/apollo/apollo-client'
import { Medias } from '@/js/utils/medias'

const Pulling = {}
const interval = 1000
const nb_steps_to_pull = Math.round(parseInt(process.env.VUE_APP_PULLING_INTERVAL) / interval)
let timer = false
let last_pulled_step = 0
let players_loaded = false

const videos_slugs = [ 'tutorial', 'intro', 'outro' ]

Pulling.init = () => {
	if (!timer) {
		timer = new ClockWorker

		timer.onmessage = function({ data }) {
			Pulling.pull()
		}
	}
}



Pulling.start = () => {
	if (!timer) Pulling.init()

	let room = store.getters.game.room
	if (room) {
		timer.postMessage({ cmd: 'setRemainingSteps', data: parseInt(room.timer) })
		timer.postMessage({ cmd: 'setTotalSteps', data: parseInt(room.timer_total) })
		timer.postMessage({ cmd: 'setInterval', data: interval })
		timer.postMessage({ cmd: 'start' })
	}
}


Pulling.stop = () => {
	if (timer) timer.postMessage({ cmd: 'stop' })
	timer = false
}


Pulling.pull = async () => {
	// return
	

	last_pulled_step++
	if (store.getters.game.room) {

		// PULLING
		if (last_pulled_step >= nb_steps_to_pull) {
			let room = await Apollo.query('GET_ROOM', { id: store.getters.game.room.id })
			// const room = store.getters.game.room
			// console.info('pulled')
			// console.info('%cpulled', "color: #549DB9")
			if (room) {
				if (room.state === 'paused') {
					room.timer = store.getters.game.room.timer
					room.game_clock = store.getters.game.room.game_clock
				}
				store.commit('EDIT_GAME_STATE', { room })

				if (store.getters.logged_user.type === 'game-master') {
					store.dispatch('update_chirps')
				}

				else if (store.getters.logged_user.type === 'player') {

					store.dispatch('load_player_files')

					if (!players_loaded && store.getters.game.room.state === 'running') {
						store.dispatch('load_players', { room })
						players_loaded = true
					}
					store.dispatch('update_chirps')
					
					if (store.getters.game.room.state === 'running') {

						// Show info box
						if (store.getters.game.info_boxes && store.getters.game.room.timer) {
							const t = (store.getters.game.room.timer_total - store.getters.game.room.timer)
							if (t) {
								const info_boxes = store.getters.game.info_boxes.filter((i) => {
									return (i.timer <= t && !i.displayed) && (i.info_category === 'CO' || (i.info_category === 'OP' && room.infobox && (room.infobox.find(f => parseInt(f.id) === parseInt(i.id))) ))
								})
								info_boxes.forEach((info_box) => {
									if (!store.getters.logged_user.infobox_status || !store.getters.logged_user.infobox_status[info_box.id]) {
										store.commit('EDIT_GAME_INFO_BOX', { info_box, displayed: true, details: room.infobox.find(f => parseInt(f.id) === parseInt(info_box.id)) })
										store.dispatch('add_info_box', { info_box })
									}
								})
							}
						}

						// Majority pulling
						const majority_pulling = store.getters.progress.majority_pulling
						if (majority_pulling && majority_pulling.question) {
							const result = await Apollo.query('GET_POLLING_RESULT', { room_id: store.getters.game.room.id, question_id: majority_pulling.question.id, calculate: true })
							if (result && result.status /*&& result.result > -1*/) {

								// Algorithmic question
								if (majority_pulling.question.type === 'Algorithmic') {
									store.commit('EDIT_APP_STATE', {
										algorithmic_results: {
											question: majority_pulling.question,
											majority_answer: result
										}
									})
								}

								// Majority question
								else {
									const answer = majority_pulling.question.answer.find((a) => { return parseInt(a.id) === parseInt(result.result) })
									if (answer) {
										let info_box = {
											id: "outcome",
											description: "# Decision made \r\r\r" + answer.answer + "\r\r\r# Outcome\r\r",
											style: 'Public_satisfaction_outcome'
										}
										if (answer.feedback) info_box.description += answer.feedback

										store.dispatch('add_info_box', {
											info_box,
											params: {
												public_opinion: result.public_opinion,
												impact: answer.impact
											}
										})
									}
								}
								store.commit('EDIT_PROGRESS_QUESTION', {
									question: majority_pulling.question,
									majority_answer: result
								})
								store.commit('EDIT_PROGRESS_STATE', { majority_pulling: null })
								store.dispatch('update_unit_details')
							}
						}

					}


					// Push video
					videos_slugs.some((video) => {
						if (room[video + '_event'] && (room.episode[video] || store.getters.game.scenario[video])) {
							let time = Math.floor(Date.parse(room[video + '_event'].replace(' ', 'T')) / 1000)
							if (time && time !== store.getters.logged_user.video_status[video]) {
								store.commit('EDIT_GAME_STATE', { playing_video: video })
								store.dispatch('update_video_status', { key: video, value: time })
								return true
							}
						}
					})
				}
			}
			last_pulled_step = 0

		}

		// NO PULLING
		else {
			const room = store.getters.game.room
			if (room.state === 'running') {
				if (parseInt(room.timer) > 0) {
					await store.commit('EDIT_ROOM', { room, timer: room.timer - 1 })
				}
			}
		}

		if (store.getters.game.room.state === 'running') {
			
			// On regarde si le timer d'une question se termine
			const current_question = store.getters.questions.find((question) => {
				return question.timer + question.timeout === store.getters.game.room.timer_total - store.getters.game.room.timer
			})
			if (current_question) {
				store.dispatch('end_question', { question: current_question })
			}

			// On vérifie les outcomes
			store.dispatch('check_outcomes', { room: store.getters.game.room })
		}


	} else
		Pulling.stop()
}


export default Pulling
