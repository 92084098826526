<template>
	<div class="player-notation">
		<div class="player">
			<div class="name">{{ review.role_name }} <span v-if="isMe" class="me">(you)</span></div>
			<div class="username">{{ review.player_name }}</div>
		</div>
		<div class="stars" @mouseleave="() => star_hover = 0">
			<div v-for="i in 10" :key="i" class="star star-1" :class="{ active: i <= review.rating, over: i <= star_hover }"
				@mouseenter="() => star_hover = i"
				v-hammer:tap="() => set_note(i)"
			>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			star_hover: 0,
		}
	},
	props: {
		review: { type: Object, required: true },
		isMe: { type: Boolean, default: false },
	},
	methods: {
		set_note(n) {
			this.$emit('set-note', { player_id: this.review.player_id, rating: n })
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.player-notation
	width 100%
	flex space-between center
	padding 16px 32px 16px 24px
	border-radius 8px
	background-color var(--color-summary-row-bg)
	.player
		color var(--color-summary-row-text)
		.name
			height 28px
			font-size 2rem
			.me
				font-size 1.4rem
		.username
			height 20px
			font-size 1.4rem
	.stars
		flex flex-start flex-start
		&:hover
			.star
				&.active
					filter brightness(0)
					opacity 0.75
				&.over
					filter brightness(1)
					opacity 1
		.star
			width 16px
			height 32px
			background url('../../../assets/img/star.svg') left top no-repeat
			background-size 32px 32px
			cursor pointer
			&:nth-child(odd)
				margin-left 8px
			&:nth-child(even)
				background-position right top
			
			&:not(.active)
				filter brightness(0)
				opacity 0.5
			&.over
				filter brightness(1.5)
				opacity 1

</style>