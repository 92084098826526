<template>
	<div v-if="name" class="ui-icon" :style="{ width: size + 'px', height: size + 'px' }">
		<img v-if="name === 'news/nola-post'" :src="require('@/assets/img/' + name + '.svg')">
		<inline-svg v-else :src="require('@/assets/img/' + name + '.svg')"/>
	</div>
</template>

<script>
export default {
	name: 'ui-icon',
	data() {
		return {
			
		}
	},
	props: {
		name: { default: false },
		size: { default: 16 }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.ui-icon
	display inline-block
	flex-shrink 0
	&.size-16
		width 16px
		height 16px
	img
		position absolute
		left 0
		top 0
		height 100%
		width 100%
	>>> svg
		position absolute
		left 0
		top 0
		height 100%
		width 100%
	>>> svg, >>> svg *
		fill currentColor !important
		stroke none !important
		

</style>
