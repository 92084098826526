import Vue from 'vue'
import Vuebar from '@/js/vuebar' 
import Feature from 'feature.js'
Vue.use(Vuebar)

const Scrollbar = {}

const is_mac = function() {
	return window.navigator.platform == 'MacIntel'
}

const update_scrollbar = function(parent) {
	let target = parent.firstChild
	target.setAttribute('data-scroll', 0)
	target.setAttribute('data-y', 0)
	target.style.transform = ''

	let helper = parent.querySelector(':scope > .scroll-helper')
	helper.style.top = 0

	Vue.nextTick(() => {
		let percent = parent.clientHeight * 100 / target.clientHeight
		if (percent >= 100) {
			percent = 100
			helper.style.display = 'none'
		} else {
			helper.style.height = percent + '%'
			helper.style.display = 'block'
		}
	})
}

Scrollbar.install = function (Vue, options) {

	Vue.directive('scrollbar', {

		// componentUpdated(el) {
		// 	if (Feature.touch) {
		// 		update_scrollbar(el)
		// 	}
		// },

		bind (el, binding, vnode, oldVnode) {

			if (binding.value && binding.value.maxHeight) {
				Vue.nextTick(() => {
					if (el.clientHeight > binding.value.maxHeight) el.style.height = binding.value.maxHeight + 'px'
				})
			}

			// Desktop
			if (!Feature.touch && !is_mac()) {
				Vue.prototype.$vuebar.initScrollbar(el, { scrollThrottle: 10 })

				if (binding.value && binding.value.on_update) {
					el.firstChild.addEventListener('scroll', (e) => {
						binding.value['on_update'](e)
					})
				}
			}

			// Mobile natif
			else {
				el.style.overflow = 'auto'
				el.style['-webkit-overflow-scrolling'] = 'touch'

				if (binding.value && binding.value.on_update) {
					el.addEventListener('scroll', (e) => {
						binding.value['on_update'](e)
					})
				}
			}

			// Mobile hammer
			// else {
			// 	let parent = el
			// 	let target = el.firstChild

			// 	parent.style.display = 'block'
			// 	parent.style.overflow = 'hidden'

			// 	let helper = document.createElement("div")
			// 	helper.classList.add('scroll-helper')
			// 	parent.appendChild(helper)

			// 	let mc = new Hammer.Manager(target)
			// 	mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_VERTICAL }) )

			// 	const get_y = function(e, final) {
			// 		let y = (parseFloat(target.getAttribute('data-scroll')) || 0) + e.deltaY
			// 		if (final) y = y + (e.velocityY * 150)
			// 		if (parent.clientHeight - target.clientHeight > y) y = parent.clientHeight - target.clientHeight
			// 		if (y > 0) y = 0
			// 		return y
			// 	}

			// 	mc.on("panstart", (e) => {
			// 		parent.classList.add('panning')
			// 		target.classList.remove('is-decelerating')
			// 		helper.classList.remove('is-decelerating')
			// 	})

			// 	mc.on("pan", (e) => {
			// 		let y = get_y(e, false)

			// 		target.style.transform = 'translateY(' + y + 'px)'
			// 		target.setAttribute('data-y', y)

			// 		let percent = -(y * 100 / target.clientHeight)
			// 		helper.style.top = '' + percent + '%'

			// 		if (binding.value && binding.value.on_update) binding.value['on_update'](e)
			// 	})

			// 	mc.on("panend", (e) => {

			// 		target.classList.add('is-decelerating')
			// 		helper.classList.add('is-decelerating')

			// 		let y = get_y(e, true)
			// 		target.style.transform = 'translateY(' + y + 'px)'
			// 		let percent = -(y * 100 / target.clientHeight)
			// 		helper.style.top = '' + percent + '%'

			// 		target.setAttribute('data-scroll', y)
			// 		target.setAttribute('data-y', 0)
			// 		setTimeout(() => {
			// 			parent.classList.remove('panning')
			// 			target.classList.remove('is-decelerating')
			// 			helper.classList.remove('is-decelerating')
			// 		}, 400)
			// 	})

			// 	update_scrollbar(parent)

			// 	window.addEventListener('resize', () => {
			// 		update_scrollbar(parent)
			// 	})
			// 	// toggle_bottom_gradient(parent, true)
			// }
		}

	})

}

export default Scrollbar