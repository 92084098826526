<template>
	<div class="ui-video">
		<video :controls="!light" :autoplay="autoplay" :muted="muted" width="100%" height="100%">
			<source :src="src">
			Your browser does not support the video tag.
		</video>
	</div>
</template>

<script>
// import Plyr from 'plyr'
// import 'plyr/dist/plyr.css'

export default {
	name: 'ui-video',
	props: {
		src: { default: null },
		light: { default: false },
		autoplay: { default: false },
		muted: { default: false }
	},
	// mounted() {
	// 	let controls = [ 'play-large', 'play', 'mute', 'volume', 'progress', 'current-time', 'duration', 'fullscreen' ]
	// 	let clickToPlay = true
	// 	if (this.light) {
	// 		controls = [ 'mute', 'volume', 'fullscreen' ]
	// 		clickToPlay = false
	// 	}
		
	// 	const player = new Plyr('#player', {
	// 		controls,
	// 		clickToPlay,
	// 		autoplay: this.autoplay
	// 	});
	// }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.ui-video
	width 100%
	height 100%
	video 
		outline none

</style>
