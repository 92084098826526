<template>
	<div v-if="display_user" class="chirp-user" :class="{ new: nb_new > 0 }">
		<div class="chirp-user-content">
			<div class="part-left">
				<div class="avatar">
					<div v-if="display_user.avatar" class="img" v-bg="{ name: display_user.avatar, type: 'avatar' }"></div>
					<div v-else-if="display_user.avatar_initials" class="initials">{{ display_user.avatar_initials }}</div>
				</div>
			</div>
			<div class="part-right">
				<p class="name">{{ display_user.name }}</p>
				<p v-if="last_message && last_message.message" class="description">
					<span v-if="last_message.sender && parseInt(last_message.sender.id) === parseInt(logged_user.id)">You: </span>
					{{ last_message.message.substr(0, 70-1) + (last_message.message.length > 70 ? '&hellip;' : '') }}
				</p>
				<div v-if="nb_new > 0" class="nb-new">{{ nb_new }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { get_initials } from '@/js/utils/utils'

export default {
	name: 'chirp-user',
	data() {
		return {
			
		}
	},
	props: {
		user: { default: false },
		channel: { default: false },
		question: { default: null }
	},
	computed: {
		...mapGetters([ 'game', 'logged_user', 'chirp', 'progress', 'questions', 'current_question_conversation' ]),
		display_user() {
			if (this.user) return this.user
			if (this.channel) {
				// if (this.last_message) {
				// 	if (this.last_message.sender_player && this.last_message.sender_player.avatar) {
				// 		return { id: -1, name: this.channel, avatar: this.last_message.sender_player.avatar }
				// 	} else if (this.last_message.sender) {
				// 		return { id: -1, name: this.channel, avatar_initials: get_initials(this.last_message.sender.name)  }
				// 	}
				// }
				return { id: -1, name: this.channel, avatar_initials: get_initials(this.channel) }
			}
			return null
		},
		last_message() {
			if (this.user) return this.messages[this.messages.length - 1]
			if (this.channel) return this.chirp.messages.find(m => m.channel === this.channel)
			return null
		},
		nb_new() {
			let n = 0
			if (this.current_dm_question && !this.selectedAnswer) n += 1
			if (this.user) n += this.chirp.messages.filter((m) => { return m.sender && !m.channel && parseInt(m.sender.id) === parseInt(this.display_user.id) && !m.read_by.includes(parseInt(this.logged_user.id)) && parseInt(m.sender.id) !== parseInt(this.logged_user.id) }).length
			if (this.channel) n += this.chirp.messages.filter((m) => { return !m.read_by.includes(parseInt(this.logged_user.id)) && m.channel === this.channel }).length
			return n
		},
		selectedAnswer() {
			if (this.current_dm_question) return this.current_dm_question.answer.find(({ id }) => this.progress.selected_answers.includes(parseInt(id)))
			return null
		},

		past_dm_questions() {
			if (this.questions.length > 0) {
				const t = this.game.room.timer_total - this.game.room.timer
				return this.questions.filter((q) => {
					return q.timer < t && q.direct_message && q.sender_id_id && parseInt(q?.sender_id_id) === parseInt(this.user?.role?.id)
				})
			}
			return []
		},

		current_dm_question() {
			if (
				this.current_question_conversation &&
				parseInt(this.current_question_conversation?.sender_id_id) === parseInt(this.user?.role?.id) &&
				!this.current_question_conversation.answer.find(a => this.progress.selected_answers.includes(parseInt(a.id)))
			)
				return this.current_question_conversation
			return null
		},

		messages() {
			const messages = []

			if (this.user?.id) {

				// DM questions
				this.past_dm_questions.forEach(question => {
					messages.push({ sender: this.user, message: question.question, time: question.timer })
					if (question.description) messages.push({ sender: this.user, message: question.description, time: question.timer + 0.1 })
					const answer = question.answer.find(a => this.progress.selected_answers.includes(parseInt(a.id)) )
					if (answer) {
						messages.push({ sender: this.logged_user, message: answer.answer, time: question.timer + 0.2 })
						if (answer.id !== this.answering_id) {
							messages.push({ sender: this.user, message: answer.feedback, time: question.timer + 0.3 })
						}
					}
				})

				// Private messages
				this.chirp.messages.forEach((m) => {
					if (m.recipient && (m.recipient.id === this.user.id || m.sender.id === this.user.id) && m.message.trim()) {
						messages.push({ sender: m.sender, message: m.message, time: m.in_game_created })
					}
				})
			}

			return messages.sort((a, b) => a.time > b.time ? 1 : -1)
		}

	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'

.chirp-user
	color var(--color-chirp-user-text)
	cursor pointer
	margin 8px 0
	&.new
		background-color var(--color-chirp-user-bg-hover)
		color var(--color-chirp-user-text-hover)
		border-radius 4px
		.part-right
			font-weight 700
	.chirp-user-content
		padding 8px
		.part-left
			float left
			width 64px
			flex flex-start flex-start
			.avatar
				width 64px
				height 64px
				background-color var(--color-chirp-user-avatar-bg)
				border-radius 50%
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
					border-radius 50%
				.initials
					width 100%
					height 100%
					background var(--color-chirp-user-avatar-bg)
					color var(--color-chirp-user-avatar-text)
					flex center center
					font-weight 700
					font-size 2rem
					border-radius 50%
		.part-right
			padding-left 16px
			line-height 20px
			height 64px
			flex center flex-start
			flex-direction column
			overflow hidden
			.name
				font-weight 600
			.nb-new
				position absolute
				right 8px
				top calc(50% - 16px)
				width 32px
				height 32px
				border-radius 50%
				background-color var(--color-chirp-user-new-badge-bg)
				color var(--color-chirp-user-new-badge-text)
				line-height (@height + 0px)
				text-align center
				font-weight 700
				font-size 1.6rem
			
</style>
