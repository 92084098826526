<template>
	<div class="modal-image-viewer">
		<div class="bg" v-hammer:tap="close"></div>
		<img :src="url">
		<div class="close" v-hammer:tap="close"></div>
	</div>
</template>

<script>
export default {
	props: {
		url: { type: String, required: true }
	},
	methods: {
		close() {
			this.$store.commit('EDIT_APP_STATE', { image_viewer_url: null })
		}
	}
}
</script>

<style lang="stylus" scoped>

.modal-image-viewer
	position fixed
	left 0
	right 0
	top 0
	bottom 0
	width 100%
	height 100%
	display flex
	align-items center
	justify-content center
	z-index 2
	.bg
		position absolute
		left 0
		right 0
		top 0
		bottom 0
		background-color alpha(#000, 75%)
	img
		margin 0 auto
		max-width 95%
		max-height 95%
		object-fit contain
		background-color #000
		box-shadow 0 0 0 4px #000
		pointer-events auto
	.close
		position absolute
		right 16px
		top 16px
		width 80px
		height 80px
		background url("../../assets/img/close-round-white.svg") center center no-repeat
		background-size contain
		cursor pointer
		opacity 0.75
		&:hover
			opacity 1
			transform scale(1.05)
		&:active
			opacity 1
			transform scale(0.95)

</style>