<template>
	<div class="chirp">

		<div class="chirp-head">

			<div class="top">
				<div class="network wifi"><ui-icon name="chirp/network-1" :size="24" /></div>
				<div class="network phone"><ui-icon name="chirp/network-2" :size="24" /></div>
				<div v-if="chirp.route.name === 'feed'" class="input-search">
					<ui-input type="text" placeholder="Search" @input="search" ref="input_search" />
					<button class="button"><ui-icon name="search" :size="24" /></button>
				</div>
				<div v-show="show_back_button" class="back" title="Back" v-hammer:tap="() => back()">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
				</div>
				<div v-if="chirp.route.name === 'feed'" class="write-new" v-hammer:tap="() => toggle_form_post()" title="Write a chirp"><ui-icon name="edit" :size="16" /></div>
				<div class="close" v-hammer:tap="() => close()" title="Close"><ui-icon name="close-blue-dark" :size="16" /></div>
			</div>

			<div v-if="chirp.route.name === 'feed'" v-show="form_post_visible" class="bottom" :class="{ 'has-medias': chirp.current.selected_media.length }">
				<form class="form-post" @submit.prevent="send()">
					<textarea v-model="text" class="input" placeholder="Write a chirp" ref="post"></textarea>
					<span class="nb-characters" :class="{ error: nb_chars_left < 0 }">{{ nb_chars_left }}<span class="char">char</span></span>
					<button class="button" type="submit"><ui-icon name="send-white" :size="24" /></button>
					<button class="button attach" type="button" v-hammer:tap="() => show_media_selection()"><ui-icon name="image" :size="24" /></button>
					<div v-if="chirp.current.selected_media.length" class="medias">
						<div v-for="media in chirp.current.selected_media" :key="media.id" class="media">
							<div class="img" v-bg="media"></div>
							<div class="delete" v-hammer:tap="() => remove_selected_media(media)"></div>
						</div>
					</div>
					<div v-show="loading" class="loading"><ui-icon name="button-loader" size="48" /></div>
				</form>
			</div>

		</div>

		<div class="chirp-content" :class="['route-' + chirp.route.name, { 'has-form-post': form_post_visible, 'has-medias': chirp.current.selected_media.length && chirp.route.name === 'feed' } ]">
			<component :is="'chirp-' + chirp.route.name" :search="q" />
		</div>

		<div v-if="!app.is_ipad_theme" class="chirp-footer">
			<!-- <div class="chirp-menu-item menu-item-home" :class="{ active: chirp.route.meta.menu == 'feed' }" v-hammer:tap="() => set_chirp_route('feed')">
				<span class="icon">
					<ui-icon class="icon-default" name="chirp/chirp-home" :size="32" />
					<ui-icon class="icon-active" name="chirp/chirp-home-active" :size="32" />
					<span v-if="nb_new_chirps > 0" class="nb">{{ nb_new_chirps }}</span>
				</span>
				<span class="name">Feed</span>
			</div>
			<div class="chirp-menu-item menu-item-messages" :class="{ active: chirp.route.meta.menu == 'messages' }" v-hammer:tap="() => set_chirp_route('messages')">
				<span class="icon">
					<ui-icon class="icon-default" name="chirp/chirp-messages" :size="32" />
					<ui-icon class="icon-active" name="chirp/chirp-messages-active" :size="32" />
					<span v-if="nb_new_messages > 0" class="nb">{{ nb_new_messages }}</span>
				</span>
				<span class="name">Messages</span>
			</div>
			<div class="chirp-menu-item menu-item-form" :class="{ active: chirp.route.meta.menu == 'post' }" v-hammer:tap="() => set_chirp_route('post')">
				<span class="icon">
					<ui-icon class="icon-default" name="chirp/chirp-post" :size="32" />
					<ui-icon class="icon-active" name="chirp/chirp-post-active" :size="32" />
				</span>
				<span class="name">Post</span>
			</div> -->
		</div>
		
		<transition name="chirp">
			<chirp-media-selection v-if="chirp.current.media_selection_visible" />
		</transition>
		<chirp-media-viewer v-if="chirp.current.media_viewer" />

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'
import ChirpFeed from '@/components/game/chirp/screens/chirp-feed'
import ChirpMessages from '@/components/game/chirp/screens/chirp-messages'
import ChirpConversation from '@/components/game/chirp/screens/chirp-conversation'
import ChirpConversationQuestion from '@/components/game/chirp/screens/chirp-conversation-question'
import ChirpPlayers from '@/components/game/chirp/screens/chirp-players'
import ChirpPost from '@/components/game/chirp/screens/chirp-post'
import ChirpProfile from '@/components/game/chirp/screens/chirp-profile'
import ChirpMediaSelection from '@/components/game/chirp/elements/chirp-media-selection'
import ChirpMediaViewer from '@/components/game/chirp/elements/chirp-media-viewer'
import UiInput from '@/components/ui/ui-input'

export default {
	name: 'chirp',
	data() {
		return {
			q: '',
			text: '',
			loading: false,
			form_post_visible: true
		}
	},
	computed: {
		...mapGetters([ 'app', 'chirp', 'logged_user' ]),
		show_back_button() {
			return getValueOnPath(this.chirp, 'route.meta.backButton')
		},
		nb_new_chirps() {
			return this.chirp.feed.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id != this.logged_user.id }).length
		},
		nb_new_messages() {
			return this.chirp.messages.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id !== this.logged_user.id }).length
		},
		nb_chars_left() {
			return 140 - this.text.length
		}
	},
	watch: {
		'chirp.route'(newValue, oldValue) {
			this.q = ''
			if (this.$refs.input_search && this.$refs.input_search.$el) this.$refs.input_search.$el.value = ''
		}
	},
	methods: {
		toggle_form_post() {
			this.form_post_visible = !this.form_post_visible
		},
		close() {
			this.$store.dispatch('set_chirp', { route: null })
			if (this.app.is_ipad_theme) {
				this.$router.push({ name: 'game' })
			}
		},
		set_chirp_route(name) {
			if (this.chirp.name == name) this.back()
			this.$store.dispatch('set_chirp_route', { name })
		},
		back() {
			if (this.chirp.route.name == 'players' || this.chirp.route.name == 'conversation' || this.chirp.route.name == 'conversation-question') this.$store.dispatch('set_chirp_route', { name: 'messages' })
		},
		search(q) {
			this.q = q
		},
		show_media_selection() {
			// let media_selection_multiple = (this.type == 'chirp')
			this.$store.dispatch('set_chirp_current', { media_selection_visible: true })
		},
		remove_selected_media(media) {
			this.$store.dispatch('set_chirp_current', { selected_media: this.chirp.current.selected_media.filter(m => m.id !== media.id) })
		},
		async send() {
			if (!this.loading && this.nb_chars_left >= 0) {
				this.loading = true
				let message_id = await this.$store.dispatch('send_message', {
					title: "",
					message: this.text,
					channel: '',
					tags: [ 'all' ],
					message_type: 'Chirp',
					medias: this.chirp.current.selected_media.map((m) => { return m.url })
				})
				if (message_id && message_id > 0) {
					this.text = ''
					this.$store.dispatch('set_chirp_current', { media_selection_visible: false, selected_media: [] })
					this.$store.dispatch('add_notification', { status: 'success', message: "Sent!" })
				}
				this.loading = false
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			if (this.$refs.post) this.$refs.post.focus()
		})
	},
	created() {
		if (this.app.is_ipad_theme) this.form_post_visible = false
	},
	components: { ChirpFeed, ChirpMessages, ChirpConversation, ChirpConversationQuestion, ChirpPlayers, ChirpPost, ChirpProfile, UiInput, ChirpMediaSelection, ChirpMediaViewer }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.ipad-theme
	.chirp
		absolute auto
		left calc(50% - 450px)
		// right calc(50% + 450px)
		top calc(50% - 250px)
		// bottom calc(50% + 250px)
		width 900px
		height 500px
		&.chirp-enter-active, &.chirp-leave-active
			transition 0.15s easeOutQuart
		&.chirp-enter, &.chirp-leave-to
			transform scale(0.95)
			opacity 0
		.chirp-head .top
			max-width none
		.chirp-content
			height calc(100% - 48px)
			&.has-medias
				height "calc(100% - %s)" % (188px + 64px)
			&.has-form-post
				height "calc(100% - %s)" % (188px)
				&.has-medias
					height "calc(100% - %s)" % (188px + 64px)

.chirp
	&.chirp-enter-active, &.chirp-leave-active
		transition 0.25s easeOutQuart
	&.chirp-enter, &.chirp-leave-to
		transform translateX(105%)


.chirp-head-enter-active, .chirp-head-leave-active
	transition 0.25s easeOutQuart
.chirp-head-enter
	transform translateX(24px)
	opacity 0
.chirp-head-leave-to
	transform translateX(-24px)
	opacity 0
	


#app.mobile
	.chirp-enter-active, .chirp-leave-active
		transition none
	.chirp-enter, .chirp-leave-to
		transform none
	&.mobile-keyboard
		.chirp
			transform translateY(-64px)
			height 100%
			.chirp-content
				height calc(100% - 64px)
			.chirp-footer
				display none
	.chirp
		top 64px
		width 100%
		height "calc(100% - %s)" % (64px + 64px)
		// .chirp-head
		// 	height (96px / 2)
		// 	.top
		// 		display none
		// .chirp-content
		// 	height "calc(100% - %s)" % (48px + 64px)
		.chirp-content
			height "calc(100% - %s)" % (48px + 0)
			&.route-feed
				height "calc(100% - %s)" % (188px + 0)
		.chirp-footer
			display none
		>>> .chirp-media-selection
			right 0
			width 100%

#app.tablet-vertical
	.chirp
		// width calc(100% - 97px)
		width 100%

.chirp
	position fixed
	right 0
	top 80px
	bottom 0
	height calc(100% - 160px)
	width (64px * 5)
	width 420px
	background-color var(--color-chirp-bg)
	box-shadow -4px 0 8px alpha(#000, 25%)
	z-index 2
	.chirp-head
		background-color var(--color-chirp-header-bg)
		color var(--color-chirp-header-text)
		.top, .bottom
			height (96px / 2)
			max-width (800px + 32px)
			margin 0 auto
		.top
			flex center center
			.close
				position absolute
				right 16px
				top 8px
				flex center center
				width 32px
				height 32px
				background-color var(--color-bottom-bar-bg)
				border 1px solid var(--color-chirp-header-text)
				border-radius 4px
				cursor pointer
			.write-new
				position absolute
				right 56px
				top 8px
				flex center center
				width 32px
				height 32px
				background-color var(--color-bottom-bar-bg)
				border 1px solid var(--color-chirp-header-text)
				border-radius 4px
				cursor pointer
			.back
				position absolute
				right 56px
				top 8px
				flex center center
				width 32px
				height 32px
				background-color var(--color-bottom-bar-bg)
				border 1px solid var(--color-chirp-header-text)
				border-radius 4px
				cursor pointer
				>>> .ui-icon
					transform rotate(180deg)
					svg
						fill none !important
			.network
				position absolute
				left 16px
				top 12px
				&.phone
					left 48px
			.input-search
				width calc(100% - 180px)
				margin-right 16px
				// margin-left 24px
				height 32px
				background-color var(--color-chirp-header-input-bg)
				border-radius 24px
				color var(--color-chirp-header-input-text)
				::-webkit-input-placeholder
					color var(--color-chirp-header-input-placeholder)
					opacity 1
				::-moz-placeholder
					color var(--color-chirp-header-input-placeholder)
					opacity 1
				:-ms-input-placeholder
					color var(--color-chirp-header-input-placeholder)
					opacity 1
				:-moz-placeholder
					color var(--color-chirp-header-input-placeholder)
					opacity 1
				.ui-input
					padding 0 40px 0 16px
				.button
					position absolute
					right 0
					top 0
					width 40px
					height 100%
					flex center center
					background transparent
					cursor pointer
					border none
					font inherit
					color var(--color-chirp-header-input-placeholder)
		.bottom
			height 140px
			padding 16px
			&:after
				content ''
				position absolute
				left 16px
				top 0
				height 1px
				background-color var(--color-chirp-header-text)
				width calc(100% - 32px)
			&.has-medias
				height (140px + 64px)
				.form-post
					.nb-characters
						bottom 64px
			.form-post
				.input
					display block
					width 100%
					height 108px
					padding 12px 44px 12px 12px
					border none
					background var(--color-chirp-header-input-bg)
					border-radius 4px
					resize none
					font inherit
					color var(--color-chirp-header-input-text)
					outline none
					&::-webkit-input-placeholder
						color var(--color-chirp-header-input-placeholder)
						opacity 1
					&::-moz-placeholder
						color var(--color-chirp-header-input-placeholder)
						opacity 1
					&:-ms-input-placeholder
						color var(--color-chirp-header-input-placeholder)
						opacity 1
					&:-moz-placeholder
						color var(--color-chirp-header-input-placeholder)
						opacity 1
				.nb-characters
					position absolute
					right 4px
					bottom 0
					font-size 1.2rem
					color var(--color-chirp-header-input-placeholder)
					pointer-events none
					&.error
						color var(--color-chirp-form-nb-char-text-error)
						.char:before
							background-color var(--color-chirp-form-nb-char-text-error)
					.char
						margin 0 0 0 2px
				.button
					position absolute
					right 0px
					top 0px
					background none
					padding 12px
					border none
					cursor pointer
					font inherit
					color var(--color-chirp-header-bg)
					>>> .ui-icon
						display block
					&.attach
						top 32px
				.medias
					height 56px
					margin-top 8px
					flex flex-start flex-start
					gap 8px
					.media
						width 56px
						height 56px
						border-radius 8px
						overflow hidden
						&:hover
							.delete
								opacity 1
						.img
							absolute 0
							background center center no-repeat
							background-size cover
						.delete
							absolute 0
							opacity 0
							background alpha(darken(red, 50%), 50%) url('../../../assets/img/chirp/trash-2.svg') center center no-repeat
							background-size 24px
							cursor pointer
				.loading
					absolute 0
					background var(--color-chirp-form-loading-overlay-bg)
					color var(--color-chirp-form-loading-overlay-text)
					flex center center
					border-radius 4px
					cursor wait
					opacity 0.75
	.chirp-content
		height "calc(100% - %s)" % (48px + 16px)
		overflow hidden
		background-color var(--color-chirp-bg)
		&.route-feed
			height "calc(100% - %s)" % (48px + 16px)
			&.has-medias
				height "calc(100% - %s)" % (188px + 16px + 64px)
			&.has-form-post
				height "calc(100% - %s)" % (188px + 16px)
				&.has-medias
					height "calc(100% - %s)" % (188px + 16px + 64px)
		&.route-messages
			height "calc(100% - %s)" % (48px + 16px)
		>>> .scroll
			overflow-x hidden !important
		>>> .chirp-content-wrap
			// max-width 900px
			margin 0 auto
			// background-color blue
	.chirp-footer
		height 16px
		background-color var(--color-chirp-footer-bg)
		// flex space-evenly center
		flex center center
		// max-width 900px
		margin 0 auto
		.chirp-menu-item
			flex center center
			flex-direction column
			width 28%
			height 100%
			padding-top 4px
			cursor pointer
			text-align center
			color var(--color-chirp-footer-text)
			&:nth-child(2)
				&:before
					content ''
					position absolute
					left calc(50% - 44px)
					top -16px
					background-color var(--color-chirp-footer-bg)
					border-top-left-radius 50%
					border-top-right-radius 50%
					height 80px
					width 88px
				.icon
					transform translateY(-6px) scale(1.2)
			&.menu-item-notifications
				.icon
					.ui-icon
						transform scale(1.3)
			&.active
				color var(--color-chirp-footer-text-active)
				.icon
					.icon-default
						display none
					.icon-active
						display block
			.name
				width 100%
				white-space nowrap
				line-height 16px
				text-transform uppercase
			.icon
				margin 0 auto 4px auto
				width 32px
				height 32px
				.icon-active
					display none
				.nb
					position absolute
					right -12px
					bottom -2px
					width 20px
					height 20px
					// flex center center
					border-radius 50%
					background-color var(--color-chirp-new-badge-bg)
					font-size 1.1rem
					font-weight 700
					text-align center
					line-height @height
					color var(--color-chirp-new-badge-text)
					box-shadow 0 0 0 2px var(--color-chirp-footer-bg)

</style>
