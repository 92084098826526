import Vue from 'vue'
import { slugify } from '@/js/utils/utils'
import { Medias } from '@/js/utils/medias'
import Sounds from '@/js/sounds'
import info_boxes_data from '@/data/infobox.json'



// === STATE =====================================================================================
const state = {
	online: true,
	device: 'mobile',
	mobile_keyboard: false,
	performance: 'high', // low / medium / high
	notifications: [],
	info_boxes: [],
	algorithmic_results: null,
	status: 'ready', // waiting / ready
	main_loader: true,
	loading: false,
	theme: 'dark',
	settings: false,
	is_ipad_theme: false,
	image_viewer_url: null,
}








// === GETTERS =====================================================================================
const getters = {
	app(state) { return state },
}







// === ACTIONS =====================================================================================
const actions = {


	set_app({ commit }, data) {
		commit('EDIT_APP_STATE', data)
	},


	async init_app({ commit, dispatch, state }, data) {
		console.info('%c- Version: ' + process.env.VUE_APP_VERSION, "color: #549DB9")
		console.info('%c- Environment: ' + process.env.NODE_ENV, "color: #549DB9")
		
		let user = false
		if (localStorage.getItem('sre-user')) {
			try {
				user = JSON.parse(atob(localStorage.getItem('sre-user')))

				let expiry = 12 // hours
				let now = new Date().getTime()
				if (user && ((user.created && now - user.created > expiry*60*60*1000) || (!user.created))) {
					localStorage.removeItem('ithrive-text-chirp')
					localStorage.removeItem('ithrive-text-news-title')
					localStorage.removeItem('ithrive-text-news-story')
					localStorage.removeItem('sre-user')
					user = false
				}
			} catch(e) {
				// console.error(e)
			}
		}

		Sounds.init()
		// await Medias.init()

		if (user) {
			// if (user.password) user.password = atob(user.password)
			await dispatch('login', user)
		} else {
			commit('EDIT_APP_STATE', { main_loader: false })
		}
	},



	add_info_box({ commit, state, dispatch }, data) {
		let info_box = data.info_box
		if (data.slug) {
			info_box = info_boxes_data[data.slug]
		}
		if (data.params) info_box.params = data.params

		if (info_box && info_box.id && state.info_boxes.findIndex((i) => { return i.id === info_box.id }) > -1) return false
		commit('ADD_INFO_BOX', { info_box })
		dispatch('update_infobox_status', { key: info_box.id, value: Math.floor(Date.now() / 1000) })
	},
	

	remove_info_box({ commit }, data) {
		commit('REMOVE_INFO_BOX', { info_box: data.info_box })
	},



	add_notification({ commit, state }, data) {
		commit('ADD_NOTIFICATION', { notification: data })
		setTimeout(function() {
			commit('REMOVE_NOTIFICATION', { notification: data })
		}, 5000)
	},
	

	remove_notification({ commit }, data) {
		commit('REMOVE_NOTIFICATION', { notification: data.notification })
	},

}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_APP_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	ADD_INFO_BOX(state, data) {
		if (!data.info_box.id) data.info_box.id = Math.random().toString(36).substr(2, 9)
		state.info_boxes.push(data.info_box)
	},

	REMOVE_INFO_BOX(state, data) {
		var index = state.info_boxes.findIndex((n) => n == data.info_box)
		if (index > -1) state.info_boxes.splice(index, 1)
	},


	ADD_NOTIFICATION(state, data) {
		if (!data.notification.id) data.notification.id = Math.random().toString(36).substr(2, 9)
		state.notifications.push(data.notification)
	},

	REMOVE_NOTIFICATION(state, data) {
		var index = state.notifications.findIndex((n) => n == data.notification)
		if (index > -1) state.notifications.splice(index, 1)
	},


	SET_LOADING(state, data) {
		Vue.set(state, 'loading', data.loading)
	},

}

export default { state, getters, actions, mutations }
