<template>
	<div v-if="message" class="chirp-message" :class="{ me: is_me, group: message.channel, 'is-picture': message.is_picture }">
		<div class="author">
			<div class="img" v-bg="{ name: message.sender.avatar, type: 'avatar' }"></div>
		</div>
		<div class="bubble">
			<div v-if="message.tags.includes('sending')" class="sending"></div>
			<div v-if="message.channel && !is_me && sender_player && sender_player.name" class="author-name">{{ sender_player.name }} ({{ sender_player.student_name }})</div>
			<ui-text class="text" :text="message.message"></ui-text>
			<!-- <div>{{ message.time }}</div> -->
			<div v-if="message.media.length" class="medias">
				<div v-for="media in message.media" class="media" v-hammer:tap="() => view_media(media)" :key="media.id" v-bg="{ url: media }"></div>
			</div>
		</div>
		<div v-if="message.last_of_user" class="date">Sent {{ ago }}</div>
		<div v-if="is_file_attachment && parseInt(message.sender.id) !== parseInt(logged_user.id)" class="button-attachment" v-hammer:tap="open_documents">open "documents"</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDistance from 'date-fns/formatDistance'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'chirp-message',
	data() {
		return {
			
		}
	},
	props: {
		message: { default: false }
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'chirp' ]),
		ago() {
			if (this.game.room.timer !== null) {
				if (this.message.in_game_day_created === this.game.room.episode.episode_number) {
					return formatDistance(new Date(this.message.in_game_created * 1000), new Date((this.game.room.timer_total - this.game.room.timer) * 1000), { addSuffix: true })
				}
			}
			return "From a previous session"
		},
		is_file_attachment() {
			return this.message.message.substr(0, 6) === 'File "' && this.message.message.substr(-8) === '" shared'
		},
		is_me() {
			return parseInt(this.message.sender.id) === parseInt(this.logged_user.id)
		},
		sender_player() {
			return this.game.players.find(p => parseInt(p.id) === parseInt(this.message.sender.id))
		}
	},
	methods: {
		view_media(media) {
			this.$store.dispatch('set_chirp_current', { media_viewer: {
				chirp_item: this.message,
				media
			} })
		},
		open_documents() {
			if (this.is_file_attachment) {
				this.$router.push({ path: '/game/files/documents' })
			}
		}
	},
	mounted() {
		if (!this.message.read_by.includes(this.logged_user.id)) {
			this.$store.dispatch('read_message', { message: this.message })
		}
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'

.chirp-message
	margin 0 0 8px 0
	flex flex-start flex-end
	flex-wrap wrap
	// min-height (64px + 16px)
	&:last-child
		margin 0
	&.me
		flex flex-start flex-end
		flex-direction row-reverse
		.author
			display none
			margin 0 0 0 8px
			img
				width 100%
				height 100%
				object-fit cover
				border-radius 50%
		.bubble
			background-color var(--color-chirp-conversation-bubble-me-bg)
			color var(--color-chirp-conversation-bubble-me-text)
			.sending
				absolute 0
				position absolute
				left -40px
				top calc(50% - 20px)
				width 40px
				height 40px
				background url(../../../../assets/img/loader.svg) center center no-repeat
				background-size 100% auto
		.date
			text-align right
			margin 2px 12px 0 0
	&.is-picture
		.bubble
			padding 0
			background-color transparent
			// overflow hidden
			> img
				width 100%
				height 100%
				max-height 350px
				object-fit cover
				border-radius 16px
				
	&.me + .me, &:not(.me) + :not(.me)
		margin-top -6px
				
	&.group
		.bubble
			.author-name
				margin 0 0 4px 0
				color inherit
				opacity 0.5
				// white-space nowrap
				line-height 12px
				font-size 1.2rem
				
	.author
		width 24px
		height 24px
		margin 0 8px 0 0
		.img
			width 100%
			height 100%
			background var(--color-chirp-user-avatar-bg) center center no-repeat
			background-size cover
			border-radius 50%
	.bubble
		display block
		max-width 70%
		padding 16px
		border-radius 16px
		background-color var(--color-chirp-conversation-bubble-bg)
		color var(--color-chirp-conversation-bubble-text)
		line-height 18px
		&.is-file
			text-decoration underline
			cursor pointer
			&:hover
				text-decoration none
		
		>>> .ui-text p:last-child
			margin-bottom 0
	
	.date
		display block
		width 100%
		// max-width 70%
		margin 2px 0 0 48px
		font-size 1.2rem
		color var(--color-chirp-conversation-date-text)
	
	.button-attachment
		display block
		max-width 70%
		padding 16px 16px 12px 16px
		margin 4px 0 0 32px
		border-radius 6px
		background-color var(--color-chirp-conversation-attachment-button-bg)
		color var(--color-chirp-conversation-attachment-button-text)
		line-height 18px
		font-family 'Josefin Sans'
		text-transform uppercase
		cursor pointer
		font-weight 700
	
		

	.medias
		margin 8px 0 0 0
		display block
		width 100%
		flex center center
		.media
			width 80px
			height 80px
			margin 0 8px 0 0
			background center center no-repeat
			background-size cover
			cursor pointer
			border-radius 8px
			&:last-child
				margin 0
		

</style>
