import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'



// === STATE =====================================================================================
const state = {
	loading: false,
	dragging_role: false,
	dropping_player: false,
	rooms: [],
	questions: [],
	private_messages: []
}








// === GETTERS =====================================================================================
const getters = {
	master(state) { return state },
}







// === ACTIONS =====================================================================================
const actions = {


	async create_room({ commit, dispatch, rootGetters }, data) {
		let id = await Apollo.mutate('ADD_ROOM', { name: data.name, owner_id: parseInt(rootGetters.logged_user.id), scenario_id: parseInt(data.scenario_id) })
		if (id) {
			dispatch('get_all_rooms')
		}
	},


	async get_all_rooms({ commit, state, rootGetters }, data) {
		commit('EDIT_MASTER_STATE', { loading: true })
		let r = await Apollo.query('ALL_ROOMS')
		if (r) {
			r = r.filter((room) => parseInt(room.owner_id) === parseInt(rootGetters.logged_user.id))
			let rooms = r.concat().sort((a, b) => {
				if (a.created < b.created) return 1
				else return -1
			})
			commit('EDIT_MASTER_STATE', { rooms, loading: false })
		}
	},


	async start_game_session({ commit, dispatch, rootGetters }, data) {
		if (data.room_id) {
			console.log('starting: ' + data.room_id)
			let room = await Apollo.mutate('START_ROOM_SESSION', { id: parseInt(data.room_id) })
			if (room) {
				if (rootGetters.game.room && rootGetters.game.room.id === room.id) {
					commit('EDIT_ROOM', { room: rootGetters.game.room, ...room })
				}
				commit('UPDATE_ROOM', { room })

				await dispatch('load_players', { room })

				setTimeout(() => {
					console.log('video: ' + data.room_id)
					Apollo.mutate('SET_ROOM_VIDEO_EVENT', { room_id: parseInt(data.room_id), key: 'intro' })
				}, 3000)
			}
		}
	},


	async restart_game_session({ commit, state, rootGetters }, data) {
		if (data.room_id) {
			let room = await Apollo.mutate('RESTART_ROOM_SESSION', { room_id: parseInt(data.room_id) })
			if (room) {
				if (rootGetters.game.room && rootGetters.game.room.id === room.id) {
					commit('EDIT_ROOM', { room: rootGetters.game.room, ...room })
				}
				commit('UPDATE_ROOM', { room })

				// setTimeout(() => {
				// 	Apollo.mutate('SET_ROOM_VIDEO_EVENT', { room_id: parseInt(data.room_id), key: 'intro' })
				// }, 4000)
			}
		}
	},

	// '0', 'waiting'
	// '1', 'running'
	// '2', 'stopped'
	// '3', 'paused'
	async change_room_state({ commit, state, rootGetters }, data) {
		if (data.room_id && data.state) {
			const states = { 'waiting': '0', 'running': '1', 'stopped': '2', 'paused': '3' }
			if (states[data.state]) {
				let r = await Apollo.mutate('CHANGE_ROOM_STATE', { room_id: parseInt(data.room_id), state: states[data.state] })
				if (r) {
					let room = await Apollo.query('GET_ROOM', { id: parseInt(data.room_id) })
					if (rootGetters.game.room && rootGetters.game.room.id === room.id) {
						if (room.state === 'paused') {
							if (rootGetters.game.room.timer !== rootGetters.game.room.timer_total) room.timer = rootGetters.game.room.timer
							room.game_clock = rootGetters.game.room.game_clock
						}
						commit('EDIT_ROOM', { room: rootGetters.game.room, ...room })
					}
					commit('UPDATE_ROOM', { room })
				}
			}
		}
	},
	

	async delete_room({ rootGetters, commit }, data) {
		if (data.room_id) {
			const room = await Apollo.mutate('DELETE_ROOM', { room_id: parseInt(data.room_id) })
			if (room) {
				if (rootGetters.game.room && rootGetters.game.room.id === data.room.id) {
					commit('EDIT_ROOM', { room: rootGetters.game.room, deleted: true })
				}
				commit('UPDATE_ROOM', { room })
			}
		}
	},
	

	async goto_next_episode({ rootGetters, commit }, data) {
		if (data.room_id) {
			const room = await Apollo.mutate('GOTO_NEXT_EPISODE', { room_id: data.room_id })
			if (rootGetters.game.room && rootGetters.game.room.id === room.id) {
				commit('EDIT_ROOM', { room: rootGetters.game.room, ...room })
			}
			commit('UPDATE_ROOM', { room })
		}
	},
	

	async add_players({ rootGetters, dispatch }, data) {
		let promises = []
		for (let i in data.players) {
			let player = data.players[i]
			if (player.student_number) {
				promises.push(Apollo.mutate('ADD_PLAYER', { room_id: rootGetters.game.room.id, student_number: player.student_number, student_name: player.student_number }))
			}
		}

		return Promise.all(promises).then((results) => {
			dispatch('load_players', { room: rootGetters.game.room })
		})
	},
	

	async remove_player({ rootGetters, commit }, data) {
		let removed = await Apollo.mutate('REMOVE_PLAYER', { player_id: parseInt(data.player.id) })
		if (removed) {
			commit('EDIT_PLAYER', { player: data.player, role: false, role_locked: false })
			commit('EDIT_GAME_STATE', { players: rootGetters.game.players.filter((p) => { return p.id != data.player.id }) })
		}
		return removed
	},


	async edit_player({ commit }, data) {
		let player = await Apollo.mutate('UPDATE_PLAYER', { player_id: parseInt(data.player.id), student_number: data.student_number, student_name: data.student_name })
		if (player && player.id) {
			commit('EDIT_PLAYER', { player: data.player, student_number: data.student_number, student_name: data.student_name })
		}
		return player
	},


	async set_players_roles({ rootGetters, commit }, data) {
		let players = rootGetters.game.players
		let promises = []

		players.forEach((player) => {
			if (player.role) {
				promises.push(Apollo.mutate('SET_PLAYER_ROLE', { player_id: parseInt(player.id), role_id: parseInt(player.role.id) }))
			} else
				promises.push(Apollo.mutate('SET_PLAYER_ROLE', { player_id: parseInt(player.id), role_id: 3 }))
		})

		console.log("master/set_players_roles : id du role par defaut en dur, a modifier")

		return Promise.all(promises).then((results) => {
			console.log(results)
		})
	},


	async load_all_questions({ rootGetters, commit }, data) {
		let questions = []
		const r = await Apollo.query('ALL_QUESTIONS')
		if (r) {
			if (data.room) {
				questions = r.filter((q) => q.scenario_id == data.room.scenario_id)
			} else
				questions = r
		}
		commit('EDIT_MASTER_STATE', { questions })
	},

	async load_master_polling_results({ commit, state }, data) {
		state.questions.filter((q) => {
			return (q.type !== 'Solo') && 
				((q.question_category === 'CO') || (q.question_category === 'OP' && data.room.questions.includes(parseInt(q.id)))) &&
				(
					(q.day < data.room.episode.episode_number) || 
					(q.day === data.room.episode.episode_number && data.room.timer_total - data.room.timer > 0 && q.timer <= data.room.timer_total - data.room.timer) ||
					(q.day === data.room.episode.episode_number && data.room.timer_total - data.room.timer === 0 && (data.room.state === 'running' || data.room.state === 'ending' || data.room.state === 'paused'))
				)
		}).forEach(async (question) => {
			let result = await Apollo.query('GET_POLLING_RESULT', { room_id: parseInt(data.room.id), question_id: parseInt(question.id), calculate: false })
			if (result) {
				result.result_answer = question.answer.find((a) => { return parseInt(a.id) === parseInt(result.result) })
				commit('EDIT_MASTER_QUESTION', {
					question,
					majority_answer: result
				})
			}
		})
	},


	set_like_message({ rootGetters, commit }, data) {
		if (data.message) {
			Apollo.mutate('SET_LIKE_MESSAGE', { message_id: parseInt(data.message.id), value: parseInt(data.like) })
			commit('EDIT_CHIRP', { chirp: data.message, like: parseInt(data.like) })
		}
		return false
	},

}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_MASTER_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	UPDATE_ROOM(state, data) {
		if (data.room) {
			let index = state.rooms.findIndex(r => r.id === data.room.id)
			if (index > -1) {
				Vue.set(state.rooms, index, data.room)
				// for (var i in data.room) {
				// 	if (i !== 'id') Vue.set(room, i, data.room[i])
				// }
			}
		}
	},

	ADD_ROOM(state, data) {
		state.rooms.push(data.room)
	},

	EDIT_PLAYER_ROLE(state, data) {
		for (var i in data) {
			if (i != 'role') Vue.set(data.role, i, data[i])
		}
	},

	EDIT_MASTER_QUESTION(state, data) {
		if (data.question) {
			for (var i in data) {
				if (i !== 'question') Vue.set(data.question, i, data[i])
			}
		}
	},

}

export default { state, getters, actions, mutations }
