<template>
	<div class="chirp-content-wrap">
		<div class="chirp-messages">
			<div v-if="!chirp.current.conversation" class="left-bar">
				<ui-icon name="chirp/chirp-messages" :size="40" />
			</div>
			<div v-if="!chirp.current.conversation" class="scroll" v-scrollbar>
				<div>
					<div class="new-conversation">
						<div class="button" v-hammer:tap="() => start_conversation()">
							<ui-icon class="icon-active" name="chirp/chirp-messages-active" :size="16" />
							<span class="text">Start a conversation</span>
						</div>
					</div>
					
					<!-- <div v-if="message_questions.length" class="groups">
						<div class="group">
							<div class="group-title">Decisions</div>
							<div class="group-content">
								<template>
									<chirp-user v-for="question in message_questions" :key="question.id" :user="question.sender" :question="question" v-hammer:tap="() => set_active_question(question)" />
								</template>
							</div>
						</div>
					</div> -->
					
					<div class="groups">
						<div v-if="channels.length" class="group">
							<div class="group-title">Group conversations</div>
							<div class="group-content">
								<chirp-user v-for="channel in channels" :key="channel" :channel="channel" v-hammer:tap="() => set_active_channel(channel)" />
							</div>
						</div>
						<div class="group">
							<div class="group-title">Private conversations</div>
							<div class="group-content">
								<template v-if="users.length">
									<chirp-user v-for="user in users" :key="user.id" :user="user" v-hammer:tap="() => set_active_user(user)" />
								</template>

								<div v-else class="no-users">
									No private conversations yet.
								</div>
							</div>
						</div>
					</div>

					
				</div>
			</div>
			<!-- <chirp-conversation v-else :user="chirp.active.conversation.user" />
			<chirp-conversation-new v-if="chirp.active.conversation_new" /> -->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChirpUser from '@/components/game/chirp/elements/chirp-user'
// import ChirpConversation from '@/components/game/chirp/elements/chirp-conversation'
// import ChirpConversationNew from '@/components/game/chirp/elements/chirp-conversation-new'

export default {
	name: 'chirp-messages',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'game', 'logged_user', 'chirp', 'questions' ]),
		users() {
			let o = this.chirp.messages.filter(c => c.recipient && !c.channel).reduce((a, c) => {
				if (this.logged_user.id === parseInt(c.sender.id) || this.logged_user.id === parseInt(c.recipient.id)) {
					if (!a.includes(c.recipient.id) && parseInt(c.recipient.id) !== this.logged_user.id) a.push(c.recipient.id)
					if (!a.includes(c.sender.id) && parseInt(c.sender.id) !== this.logged_user.id) a.push(c.sender.id)
				}
				return a
			}, [])
			this.questions.filter(q => this.game.room.timer <= this.game.room.timer_total - q.timer && q.direct_message && q.sender_id_id)
				.forEach(q => {
					const p = this.game.players.find(p => parseInt(p.role.id) === parseInt(q.sender_id_id))
					if (p && !o.includes(p.id)) o.push(p.id)
				})
			return this.game.players.filter(p => o.includes(p.id))
		},
		channels() {
			// return [ 'test', 'test2', 'ok' ]
			return this.logged_user.role.channel
			// return [ ... new Set(this.chirp.messages.filter(m => m.channel).map(m => m.channel)) ]
		},

		message_questions() {
			// const sender = this.game.players[0]
			// return [ { ...this.questions[1], sender } ]
			return this.questions
				.filter(q => this.game.room.timer <= this.game.room.timer_total - q.timer)
				.filter(q => q.direct_message && q.sender_id_id)
				.map(q => {
					return { ...q, sender: this.game.players.find(p => parseInt(p.role?.id) === parseInt(q.sender_id_id)) || this.game.players[0] }
				})
				.filter(q => q.sender)
		}
	},
	methods: {
		set_active_channel(channel) {
			this.$store.dispatch('set_chirp_route', { name: 'conversation', params: { channel, group: true } })
		},
		set_active_user(player) {
			this.$store.dispatch('set_chirp_route', { name: 'conversation-question', params: { player, group: false } })
		},
		set_active_question(question) {
			const player = question.sender
			this.$store.dispatch('set_chirp_route', { name: 'conversation-question', params: { player, question, group: false } })
		},
		start_conversation() {
			this.$store.dispatch('set_chirp_route', { name: 'players', params: { file: null } })
		}
	},
	components: { ChirpUser }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


#app.tablet-vertical
	.chirp-messages
		.left-bar
			display block
		.scroll
			margin-left 84px


.chirp-content-wrap
	height 100%
.chirp-messages
	height 100%
	.left-bar
		display none
		position absolute
		left 0
		top 0
		bottom 0
		width 80px
		padding 16px
		box-shadow 4px 0 0 0 var(--color-chirp-primary)

.new-conversation
	flex center center
	height 80px
	// background-color blue_light
	> .button
		height 40px
		// flex center center
		padding 0 24px
		border-radius 40px
		text-transform uppercase
		font-weight 700
		font-family 'Josefin Sans'
		background-color var(--color-chirp-new-conversation-button-bg)
		cursor pointer
		color var(--color-chirp-new-conversation-button-text)
		user-select none
		line-height (@height + 2px)
		box-shadow 0 0 4px 1px alpha(#000, 25%)
		&:hover
			box-shadow 0 0 4px 2px alpha(#000, 25%)
		&:active
			transform translateY(2px)
			box-shadow none
		>>> .ui-icon
			float left
			height 40px
			margin 12px 8px 0 0
		> .text
			display inline-block
			height @height
			line-height (@height + 4px)


.group
	padding 0 16px 16px 16px
	.group-title
		height 28px
		padding 0 8px
		margin 0 0 4px 0
		background-color var(--color-chirp-primary)
		border-radius 4px
		line-height @height
		color var(--color-chirp-bg)
		text-transform uppercase
		font-weight 700
		font-size 1.4rem
	.group-content
		>>> .chirp-user:hover
			color var(--color-chirp-user-text-hover)
			background-color var(--color-chirp-user-bg-hover)
			border-radius 8px
		.no-users
			padding 16px 0
			opacity 0.25
			text-align center
			color var(--color-chirp-primary)
			text-transform uppercase
			font-size 1.6rem
			line-height 1.2em
			user-select none


</style>
