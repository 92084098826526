<template>
	<div class="chirp-form">
		
		<chirp-form-new />

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChirpFormNew from '@/components/game/chirp/elements/chirp-form-new'

export default {
	name: 'chirp-form',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'chirp' ]),
	},
	components: { ChirpFormNew }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.chirp-form
	height calc(100% - 16px)
	// background-color blue_dark

	

</style>
