import { Medias } from '@/js/utils/medias'

const BackgroundImage = {}

const set_background_image = (el, params) => {
	if (params) {
		if (params.url) {
			el.style.backgroundImage = 'url(' + params.url + ')'
			return true
		}
		else if (params.name) {
			if (params.name.substr(0, 4).toLowerCase() === 'http') {
				el.style.backgroundImage = 'url(' + params.name + ')'
				return true
			} else {
				let media = Medias.get(params)
				if (media && media.url) {
					el.style.backgroundImage = 'url(' + media.url + ')'
					return true
				}
			}
		}
		else if (params.data) {
			el.style.backgroundImage = 'url(' + params.data + ')'
			return true
		}
	}
	el.style.backgroundImage = ''
	return false
}

BackgroundImage.install = function (Vue) {

	Vue.directive('bg', {
		inserted (el, binding) {
			set_background_image(el, binding.value)
		},
		update(el, binding) {
			if (binding.value != binding.oldValue) set_background_image(el, binding.value)
		}
	})

}

export default BackgroundImage
