<template>
	<div v-if="visible" v-show="starting || !is_profile_route" class="waiting-screen">
		<div class="waiting-screen-content">
			<div class="steps">
				<div v-if="!has_role && !starting" class="step step-1">
					<div class="waiting-text">
						<p>You succesfully joined the game !</p>
						<br>
						<p>Your role will be given to you shortly</p>
						<p>The game will begin once every player has a role !</p>
						<br>
						<div class="refresh" title="Refresh" v-hammer:tap="() => refresh()"><ui-icon name="refresh" :size="32" /></div>
					</div>
				</div>
				<div v-if="has_role && !starting" class="step step-2">
					<div class="identity">
						<p>You are</p>
						<p class="name">{{ logged_user.name }}</p>
						<p class="role">{{ logged_user.role.name }}</p>
						<!-- <div class="badge" :data-category="logged_user.role.category"></div> -->
						<p>Learn about your role while waiting for the game to start:</p>
						<ui-link to="/game/profile" class="button">
							<ui-icon name="menu/menu-profile" :size="32" />
							<span class="text">Profile</span>
						</ui-link>
						<div class="button" v-if="game.scenario.tutorial" v-hammer:tap="() => play_video('tutorial')">
							<ui-icon name="play" :size="32" />
							<span class="text">Tutorial</span>
							<div v-show="video_loading === 'tutorial'" class="loading">
								<ui-icon name="button-loader" :size="48" />
							</div>
						</div>
						<div v-if="intro_visible && game.scenario.intro" class="button" v-hammer:tap="() => play_video('intro')">
							<ui-icon name="play" :size="32" />
							<span class="text">Intro video</span>
							<div v-show="video_loading === 'intro'" class="loading">
								<ui-icon name="button-loader" :size="48" />
							</div>
						</div>
						<!-- <p>*All this information will also be available while playing.</p> -->
					</div>
				</div>
				<div v-if="starting" class="step step-3">
					<transition name="counter" mode="out-in" appear>
						<div v-if="counter === 4" class="counter" key="5">4</div>
						<div v-if="counter === 3" class="counter" key="4">3</div>
						<div v-if="counter === 2" class="counter" key="3">2</div>
						<div v-if="counter === 1" class="counter" key="2">1</div>
						<div v-if="counter === 0" class="counter" key="1">Go!</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'waiting-screen',
	data() {
		return {
			video_loading: false,
			tutorial_video_loading: false,
			visible: false,
			starting: false,
			counter: 4
		}
	},
	computed: {
		...mapGetters([ 'game', 'logged_user' ]),
		has_role() {
			if (this.logged_user.role && !this.logged_user.role.tag.includes('default')) return true
			return false
		},
		is_profile_route() {
			return this.$route.matched.length >= 2
			// return this.$route.path.substr(0, 13) == '/game/profile'
		},
		intro_visible() {
			return (this.game.room.state === 'running') || (this.logged_user && this.logged_user.video_status && this.logged_user.video_status.intro)
		},
		is_solo_scenario() {
			return [ 12, 22, 9, 21 ].includes(parseInt(this.game.scenario.id))
		},
	},
	watch: {
		'game.room.state'(newValue, oldValue) {
			if (oldValue == 'stopped' && newValue == 'running') {
				this.$store.commit('EDIT_GAME_STATE', { playing_video: null })
				this.starting = true
				this.decrement()
			}
		}
	},
	methods: {
		decrement() {
			this.counter--
			if (this.counter >= 0) setTimeout(this.decrement, 1000)
			else setTimeout(this.end, 1000)
		},
		end() {
			this.visible = false
			if (this.$route.name !== 'game') this.$router.push({ path: '/game' })
		},
		refresh() {
			location.reload()
		},
		async play_video(slug) {
			if (!this.video_loading) {
				this.video_loading = slug
				this.$store.commit('EDIT_GAME_STATE', { playing_video: slug })
				this.video_loading = false
			}
		}
	},
	created() {
		if (!this.is_solo_scenario) {
			if ((this.game.room && this.game.room.state === 'stopped') || !this.has_role) this.visible = true
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.waiting-screen
		.waiting-screen-content
			.waiting-text
				font-size 1.4rem


.waiting-screen
	absolute 0
	position fixed
	background-color var(--color-bg)
	z-index 3
	padding 32px
	user-select none
	cursor default
	.waiting-screen-content
		height 100%
		width 100%
		flex center center
		.waiting-text
			width 100%
			max-width 470px
			border-radius 4px
			padding 32px
			border 2px solid blue_lighter
			text-align center
			color gray_light
			font-size 1.6rem
			line-height 24px
			p
				// margin 0 0 4px 0
				&:last-child
					margin 0
			.refresh
				width 32px
				height 32px
				margin 0 auto
				cursor pointer
		.identity
			width 100%
			max-width 360px
			border-radius 4px
			padding 32px
			flex center center
			flex-direction column
			border 2px solid var(--color-primary)
			text-align center
			color var(--color-text)
			font-size 1.6rem
			line-height 24px
			p
				margin 0 0 16px 0
				&:last-child
					margin 0
			.name
				color var(--color-primary)
				margin 0 0 8px 0
				font-size 4rem
				line-height 40px
			.role
				color var(--color-primary)
				margin 0 0 16px 0
			.badge
				height 200px
				width 200px
				background center center no-repeat
				background-size contain
				margin 0 0 16px 0
				&[data-category="Law Enforcement"]
					background-image url(../../assets/img/emblems/law-enforcement.svg)
				&[data-category="Citizens"]
					background-image url(../../assets/img/emblems/citizens.svg)
				&[data-category="Government"]
					background-image url(../../assets/img/emblems/government.svg)
				&[data-category="Media"]
					background-image url(../../assets/img/emblems/media.svg)
			.button
				width 100%
				height 64px
				margin 0 0 16px 0
				flex center center
				background-color var(--color-waiting-screen-button-bg)
				border-radius 4px
				cursor pointer
				text-transform uppercase
				color var(--color-button-text)
				font-size 3.2rem
				&:hover
					background-color var(--color-waiting-screen-button-bg-hover)
				&:active
					background-color var(--color-waiting-screen-button-bg-hover)
					transform translateY(2px)
				.ui-icon
					margin 0 8px 0 0
				.loading
					absolute 0
					flex center center
					background-color var(--color-waiting-screen-button-bg)
					border-radius 4px
					cursor wait
					.ui-icon
						margin 0
		.counter
			font-size 8rem
			&.counter-enter-active, &.counter-leave-active
				transition .5s easeOutQuart
			&.counter-enter
				opacity 0
			&.counter-leave-to
				opacity 0
				transform scale(5)



@media (max-height: 620px)
	.waiting-screen
		.waiting-screen-content
			.identity
				padding 16px 32px
				.badge
					width 150px
					height 150px

@media (max-height: 530px)
	.waiting-screen
		.waiting-screen-content
			.identity
				padding 8px 32px
				.badge
					width 100px
					height 100px


</style>
