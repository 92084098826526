<template>
	<div v-if="text && text.length > 1" class="ui-text" v-hammer:tap="click">
		<vue-markdown :source="transformed_text" />
	</div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
// import Plyr from 'plyr'
// import 'plyr/dist/plyr.css'

export default {
	name: 'ui-text',
	data() {
		return {
			
		}
	},
	props: {
		text: { default: '' }
	},
	computed: {
		transformed_text() {
			if (this.text) {
				let str = this.text
				
				const reg_vimeo = /\[\[vimeo:(\d+)\/?[a-zA-Z0-9:]+\]\]/gm
				const matches_vimeo = [ ...str.matchAll(reg_vimeo) ]
				if (matches_vimeo.length > 0) {
					str = str.replace(reg_vimeo, '<p class="video"><iframe src="https://player.vimeo.com/video/$1?autoplay=0&title=0&byline=0&portrait=0" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>')
				}

				const reg_google_drive = /\[\[google-drive:([0-9a-zA-Z-]+)\]\]/gm
				const matches_google_drive = [ ...str.matchAll(reg_google_drive) ]
				if (matches_google_drive.length > 0) {
					// str = str.replace(reg_google_drive, '<p class="video"><iframe src="https://drive.google.com/file/d/$1/preview" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>')
					str = str.replace(reg_google_drive, '<p class="video"><video class="player-video"><source src="https://drive.google.com/uc?export=download&id=$1" /></p>')
				}
				
				const reg_video_autoplay = /\[\[video:autoplay:(http.+)\]\]/gm
				const matches_video_autoplay = [ ...str.matchAll(reg_video_autoplay) ]
				if (matches_video_autoplay.length > 0) {
					// str = str.replace(reg_video_autoplay, '<p class="video"><iframe src="https://drive.google.com/file/d/$1/preview" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>')
					str = str.replace(reg_video_autoplay, '<p class="video"><video controls autoplay src="$1" /></p>')
				}

				const reg_video = /\[\[video:(http.+)\]\]/gm
				const matches_video = [ ...str.matchAll(reg_video) ]
				if (matches_video.length > 0) {
					// str = str.replace(reg_video, '<p class="video"><iframe src="https://drive.google.com/file/d/$1/preview" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>')
					str = str.replace(reg_video, '<p class="video"><video controls src="$1" /></p>')
				}

				return str
			}
			return this.text
		}
	},
	methods: {
		click(e) {
			if (e?.target?.nodeName === 'IMG' && e.target?.currentSrc) {
				this.$store.commit('EDIT_APP_STATE', { image_viewer_url: e.target.currentSrc })
			}
			// if (e?.target?.innerHTML?.startsWith('<img')) {
			// 	const regex = /<img.*?src=\\?["|'](.*?)\\?["|']/g
			// 	const matches = [ ...e.target.innerHTML.matchAll(regex) ]
			// 	if (matches.length > 0 && matches[0].length > 1) {
			// 		const url = matches[0][1] || ""
			//		if (url) this.$store.commit('EDIT_APP_STATE', { image_viewer_url: e.target.currentSrc })
			// 	}
			// }
		}
	},
	// mounted() {
	// 	let $videos = [ ...this.$el.querySelectorAll('.video video.player-video') ]
	// 	$videos.forEach(($video) => {
	// 		const player = new Plyr($video, {
	// 			controls: [ 'play', 'play-large', 'mute', 'volume', 'progress', 'current-time', 'duration', 'fullscreen' ]
	// 		});
	// 	})
	// },
	components: { VueMarkdown }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.mobile, #app.tablet-vertical
	.ui-text >>> p.video
		iframe, .plyr, > video
			max-width 360px

.ui-text
	line-height 1.5em
	>>> ul, >>> ol
		margin-left 20px
		text-align left
		li
			margin 0 0 16px 0
			padding-left 8px
	>>> p
		margin 0 0 8px 0
		&.video
			background-color alpha(#000, 20%)
			text-align center
			iframe, .plyr, > video
				// width 480px
				// height 270px
				width 100%
				height 100%
				max-width 480px
				// max-height 270px
				margin 0 auto
				border none
				vertical-align middle
				outline none
		img
			cursor pointer
			pointer-events auto
	>>> h1
		padding 16px 0 8px 0
	>>> h2
		padding 16px 0 8px 0
	>>> h3
		padding 16px 0 8px 0
	>>> h4
		padding 16px 0 8px 0
	>>> img
		display block
		max-width 100%
		height auto
		margin 0 auto

</style>
