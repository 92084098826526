<template>
	<div class="comment-review">

		<div v-if="info_box.style === 'Comment' || info_box.style === 'Comment_Review'" class="box">
			<div class="box-header">
				<label for="post-review" class="title">Self-assessment</label>
				<label for="post-review" class="sub-title">What are your thoughts on the way you've been playing your role so far?</label>
			</div>
			<div class="box-content">
				<form-comment v-model="comment" />
			</div>
		</div>

		<div v-if="info_box.style === 'Review' || info_box.style === 'Comment_Review'" class="box">
			<div class="box-header">
				<div class="title">Assessing the other players</div>
				<div class="sub-title">What did you think about the other players?</div>
			</div>
			<div class="box-content">
				<div v-if="reviews.length" class="player-notations">
					<player-notation v-for="row in reviews" :key="row.id"
						:review="row"
						:is-me="row.player_id === logged_user.id"
						@set-note="set_note"
					/>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID } from '@/js/utils/utils'
import PlayerNotation from '@/components/game/review/player-notation'
import FormComment from '@/components/game/review/form-comment'

export default {
	data() {
		return {
			comment: '',
			reviews: []
		}
	},
	props: {
		info_box: { default: null }
	},
	computed: {
		...mapGetters([ 'game', 'logged_user' ]),
	},
	methods: {
		async get_review() {
			const review = await this.$store.dispatch('get_player_review', { player_id: this.logged_user.id })
			if (review?.comment) {
				this.comment = review?.comment || ''
			}
			if (review?.reviews) {
				try {
					this.reviews = JSON.parse(review.reviews) || []
				} catch (err) {
					console.err(err)
				}
			}
		},
		async set_note({ player_id, rating }) {
			if (player_id && rating) {
				const row = this.reviews.find(r => r.player_id === player_id)
				if (row && rating && row.rating !== rating) {
					row.rating = rating
				}
			}
		},
		async save_review() {
			await this.$store.dispatch('set_player_review', {
				player_id: this.logged_user.id,
				comment: this.comment,
				counter: this.info_box?.counter || getRandomID(),
				reviews: JSON.stringify(this.reviews)
			})
		}
	},
	created() {
		this.get_review()
	},
	beforeDestroy() {
		this.save_review()
	},
	components: { PlayerNotation, FormComment }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.comment-review
	.box
		margin 0 0 32px 0
		text-align left
		.box-header
			.title
				display block
				width 100%
				margin 0 0 16px 0
				font-size 3.2rem
				line-height 1em
				color var(--color-summary-row-text)
				font-weight 700
			.sub-title
				display block
				width 100%
				color var(--color-summary-row-text)
				margin 0 0 24px 0
				font-size 1.6rem
				line-height 1.2em
		.box-content
			.player-notations
				flex flex-start flex-start column
				gap 8px

</style>