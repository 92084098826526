import { Howl, Howler } from 'howler'

const Sounds = {}
let elements = {}
let ready = false
let loaded = false
let fade_duration = 500

Sounds.init = async () => {
	Howler.volume(1)

	loaded = true
	let time = Date.now()
	const files = require.context('@/assets/sounds/', true, /^.*\.mp3$/).keys()
	for (let id in files) {
		let slug = files[id].substr(2).replace('.mp3', '')
		elements[slug] = new Howl({
			src: [ require('@/assets/sounds/' + files[id].substr(2)) ],
			volume: 0.5
		})
	}
	console.info(files.length + ' sounds loaded in ' + (Date.now() - time) + 'ms')
}

Sounds.play = (slug, params={}) => {
	if (!loaded) Sounds.init()
	if (ready) elements[slug].play()
}

Sounds.set_ready = (value) => {
	ready = value
}

export default Sounds