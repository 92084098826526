<template>
	<transition name="slide">
		<div v-show="!app.online" class="ui-offline-bar">
			<div class="bar">
				<p><strong>OFFLINE</strong></p>
				<p>Waiting for connection...</p>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ui-offline-bar',
	computed: {
		...mapGetters([ 'app' ])
	},
	mounted() {
		window.addEventListener('online', () => {
			this.$store.dispatch('set_app', { online: true })
		})
		window.addEventListener('offline', () => {
			this.$store.dispatch('set_app', { online: false })
		})
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.slide-enter-active, .slide-leave-active,
.slide-enter-active .bar, .slide-leave-active .bar
	transition 0.25s easeOutQuart
.slide-enter .bar, .slide-leave-to .bar
	transform translateY(-100%)
	
.ui-offline-bar
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	background-color alpha(#e53935, 10%)
	z-index 5
	.bar
		position absolute
		left 0
		top 0
		right 0
		height 48px
		padding 8px 0
		background #e53935 url(../../assets/img/offline-stripe.svg) 
		box-shadow 0 1px 0px darken(#e53935, 25%)
		color #fff
		text-align center
		font-size 1.2rem
		white-space nowrap
		line-height 16px

</style>
